import {EVENT_BINDING_REQUIRED} from "../../../js/events";

export default function ($document) {
    $document
        .on(EVENT_BINDING_REQUIRED, function (e) {
            $(e.currentTarget).find('.js-spoiler').each(function (i, v) {
                (function ($element) {
                    if ($element.hasClass('collapsed')) {
                        $element.find('.card-content').hide()
                    }
                    $element.addClass('spoiler-init')
                })($(v))
            })
        })
        .on('click', '.js-spoiler .card-title', function (e) {
            if($(e.target).is('.button')){return;}
            (function ($element) {
                $element.hasClass('collapsed')
                ? $element.find('.card-content').slideUp(200)
                : $element.find('.card-content').slideDown(200)
            })($(e.currentTarget).closest('.js-spoiler').toggleClass('collapsed'))
        })

        .on('click', '.js-calculate', function (e) {
            if(!$(e.target).is('.disabled')){
                $('html').animate({ scrollTop: ($('.js-calculation-result').position().top - $('.header').innerHeight())} ,500 );
            }
        })

        .on('click', '.js-arrows', function (e) {
            if($(window).width() >= 1200) {
                if ($(this).hasClass('collapsed')){
                    $(this).removeClass('collapsed');
                    $('.js-menu-collapse').removeClass('collapsed');
                    $('.js-main').removeClass('wide');
                    $('.js-row-actions').removeClass('wide');
                }
                else {
                    $(this).addClass('collapsed');
                    $('.js-menu-collapse').addClass('collapsed');
                    $('.js-main').addClass('wide');
                    $('.js-row-actions').addClass('wide');
                }
            }
        })
}
