<template>
	<div class="form-control form-control-radio">
		<div class="label-title">{{$attrs.label||''}}</div>
		<label v-for="r in radios" class="radio">
			<input
				type="radio"
				v-bind:value="r.value"
				:name="name"
				v-model=value
			>
			<span class="radio-label">{{r.label}}</span>
		</label>
	</div>
</template>
<script>
  export default {
    watch: {
      value: {
        handler: function (n, o) {
          this.$emit('update:value', n)
        }
      }
    },
    props: {
      value: {type: String, required: true},
      radios: {type: Array, required: true},
	    name:{type:String, default:'', required:false}
    },
    emits: ['update:value']
  }
</script>