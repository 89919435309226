import {
    ATTR_DATA_UPDATE_URL_SELECTOR,
    EVENT_BINDING_REQUIRED,
    EVENT_DOM_UPDATE,
    EVENT_FORM_CLEAN,
    FORM_EVENT_SUBMIT_FILTER
} from "./events";

export function onJsonSuccess($form, response, textStatus, jqXHR) {
    let json = null;
    if (jqXHR.hasOwnProperty('responseJSON')) {
        json = jqXHR.responseJSON;
    } else if (jqXHR.getResponseHeader('x-json-api')) {
        json = JSON.parse(jqXHR.getResponseHeader('x-json-api'));
    }
    if (null !== json && typeof json === 'object' && json.hasOwnProperty('actions')) {
        json
            .actions
            .forEach(function (action) {
                switch (action) {
                    case 'toast':
                        $('<div />', {
                            html: json.params.toast
                        }).showAsToast(json.params.autoClose)
                        break;
                    case "replace-zones":
                        if (null != response) {
                            const $response = $('<div/>', {
                                html:response
                            });
                            json.zones.forEach(function (zone) {
                                let $content = $response.find(zone);
                                $(zone).replaceWith($content);
                                $content.trigger(EVENT_BINDING_REQUIRED);
                            })
                        }
                        break;
                    case "redirect":
                        window.location = json.params.redirect;
                        break;
                    case "clean":
                        $form.trigger(EVENT_FORM_CLEAN);
                        break;
                    // case "next":
                    //     asyncLoadPopup(json.next);
                    //     break;
                    // case "external":
                    //     openExternalLink(json.link);
                    //     break;
                    case "close":
                        $.fancybox.close();
                        break;
                    case "reload":
                        /**
                         * перезагрузка страницы и открытие попапа кажется слишком раздражающим
                         * поэтому отрезаем хеш чтобы fancybox не открывал заново попап.
                         */
                        document.location = document.location.href.replace(location.hash, "");
                        break;
                    case 'reload-grid':
                        $('.filter-form').trigger(FORM_EVENT_SUBMIT_FILTER);
                        break;
                    case "update":
                        /**
                         * контракт обновляемых DOM элементов
                         * - должен быть data-id для определения элемента
                         * - должен быть data-update-url для обращения за новым куском хтмл взамен старого
                         */
                        $('[data-id=' + json.id + ']' + ATTR_DATA_UPDATE_URL_SELECTOR).trigger(EVENT_DOM_UPDATE);
                        break;
                }
            })
    }
}
export     function onJsonError($form, jqXHR, textStatus, errorThrown) {
    console.error("FORM SUBMIT ERROR", arguments);
    let json = null;
    if (jqXHR.hasOwnProperty('responseJSON')) {
        json = jqXHR.responseJSON;
    } else if (jqXHR.getResponseHeader('x-json-api')) {
        json = JSON.parse(jqXHR.getResponseHeader('x-json-api'));
    }
    if (null !== json && typeof json === 'object' && json.hasOwnProperty('errors')) {
        for (let fieldname in json.errors) {
            if (!json.errors.hasOwnProperty(fieldname)) continue;
            if (fieldname === '_') {
                $form.showFormErrors(json.errors[fieldname])
            } else {
                let name = fieldname.replace(/([:.\[\]])/g, '\\$1');
                $form
                    .find('[name=' + name + ']')
                    .showFormFieldErrors(json.errors[fieldname])
            }
        }
    }
    if (window.customErrorHandler && typeof window.customErrorHandler === 'function') {
        window.customErrorHandler.apply(this, arguments)
    }
}
