<template>
  <div v-bind:class="['form-control form-control-text',{'has-value':value.length>0}]">
    <div class="form-control-text-label" v-bind:data-label="$attrs.label||''">
      <input @change="trigger" @input="trigger" type="text" v-model="value">
      <div class="label-title floating-label-title">{{ $attrs.label||'' }}</div>
      <svg class="svg-icon svg-icon-search">
        <use xlink:href="/img/svg-sprite.svg#svg-icon-search"></use>
      </svg>
    </div>
  </div>
</template>
<style>
</style>
<script>
export default {
  props: {
    value: {type:String, required:true}
  },
  emits:['update:value'],
  methods:{
    trigger(){
      this.$emit('update:value', this.value )
    }
  }
}
</script>