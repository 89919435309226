import { render } from "./FlexRow.vue?vue&type=template&id=51161ec8&scoped=true"
import script from "./FlexRow.vue?vue&type=script&lang=js"
export * from "./FlexRow.vue?vue&type=script&lang=js"

import "./FlexRow.vue?vue&type=style&index=0&id=51161ec8&scoped=true&lang=css"
script.render = render
script.__scopeId = "data-v-51161ec8"
/* hot reload */
if (module.hot) {
  script.__hmrId = "51161ec8"
  const api = __VUE_HMR_RUNTIME__
  module.hot.accept()
  if (!api.createRecord('51161ec8', script)) {
    api.reload('51161ec8', script)
  }
  
  module.hot.accept("./FlexRow.vue?vue&type=template&id=51161ec8&scoped=true", () => {
    api.rerender('51161ec8', render)
  })

}

script.__file = "src/js/components/layout/FlexRow.vue"

export default script