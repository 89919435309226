<template>
  <div
      v-bind:class="[
          'form-control form-control-text form-control-autocomplete',
          {'has-value':(value||'').length>0, 'readonly':$attrs.readonly||false,'error': errors.length>0}
          ]">
    <label ref="label" class="form-control-text-label" v-bind:data-label="$attrs.label || ''">
      <input
          @input="preFetch"
          @focusout="onFocusChange(false)"
          @focusin="onFocusChange(true)"
          @keydown="onKeypressKeydown"
          @keypress="onKeypressKeydown"
          type="text"
          ref="input"
          v-model="value"
          @paste="onPaste"
          @update:value="this.$emit('update:value', $event)"
          v-inputMask="$attrs.mask||''"
          v-bind:readonly="$attrs.readonly||false"
      >
      <div class="autocomplete-container" ref="a_container">
        <ul
            ref="a_list"
            v-bind:class="['autocomplete-list', {'open':open}]"
        >
          <li
              v-for="(item, index) in list"
              @mousedown="onItemSelect(index)"
              v-bind:class="{selected: index == selected}"
          >{{ item }}
          </li>
          <li v-if="open&&list.length==0&&notFoundText.length>0">
            {{notFoundText}}
          </li>
        </ul>
      </div>
      <span class="label-title floating-label-title">{{ $attrs.label || '' }}</span>
    </label>
    <ul class="parsley-errors-list" v-if="errors.length>0">
      <li v-for="e in errors" v-html="e"></li>
    </ul>
  </div>
</template>
<style scoped>
.autocomplete-list {
  display: none;
}

.autocomplete-list.open {
  display: block;
}
</style>
<script>
import axios from "axios";
import {debounceLatest, uniqId} from "../../utils";
import {errorSupport} from "../../mixin/error-support";

let i = 0;
const keyCode = {
  BACKSPACE: 8,
  COMMA: 188,
  DELETE: 46,
  DOWN: 40,
  END: 35,
  ENTER: 13,
  ESCAPE: 27,
  HOME: 36,
  LEFT: 37,
  PAGE_DOWN: 34,
  PAGE_UP: 33,
  PERIOD: 190,
  RIGHT: 39,
  SPACE: 32,
  TAB: 9,
  UP: 38
}
let suppressKeyPress = false,
    suppressInput = false,
    suppressKeyPressRepeat = false;

function setInputValue($textInput, text, id) {
  let $targetInput = $textInput.siblings('[type=hidden]');
  if ($targetInput.length) {
    // есть скрытый инпут - в него засовываем айдишник, а в обычный инпут - текст
    $textInput.val(text);
  } else {
    // в противном случае в видимый инпут засовываем айдишник
    $targetInput = $textInput;
  }
  $textInput.attr('data-chosen-value', text)
  $targetInput.val(id).trigger('autocomplete-change');
  $textInput.siblings('.js-autocomplete-container').html("")
}

export default {
  mixins:[errorSupport],
  props: {
    value: {type: String, required: false},
    url: {type: [String, Function], required: true},
    queryParam: {type: String, required: false, default: 'query'},
    handler: {type: Function, required: true},
    lens: {type: Function, required: true},
    step: {
      type: Number,
      default: 7,
      required: false
    },
    charLimit: {
      type: Number,
      default: 2,
      required: false
    }
  },
  emits: ['update:value', 'paste:value'],
  data() {
    return {
      loading: true,
      items: [],
      open: false,
      selected: -1,
      notFoundText: ''
    }
  },
  computed: {
    list() {
      return this.items.map(this.lens)
    },
    autocompleteUrl() {
      return typeof this.url == 'string' ? this.url : this.url()
    },
    request() {
      return debounceLatest((params, _next, _catch, _finally) => {
        return axios.get(this.autocompleteUrl, {
          ...params, headers: {
            ...params.headers || {},
            'Content-Type': 'application/json',
            'X-Requested-With': 'XMLHttpRequest'
          }
        }).then(_next).catch(_catch).finally(_finally)
      }, 250)
    }
  },
  methods: {
    onPaste(clipboardEvent){
      clipboardEvent.preventDefault();
      clipboardEvent.stopPropagation();
      this.$emit('paste:value',clipboardEvent.clipboardData.getData('text/plain'))
    },
    onFocusChange(focused) {
      this.open = focused
    },
    onAutocompleteResult(data) {
      this.items = data.data.items
      this.open = true
      this.notFoundText = data.data.no_items_label
    },
    _move(direction, event) {
      const $items = Array.from(this.$refs.a_container.querySelector('li'));
      let index = this.selected;
      if ($items.length) {
        var step = /Page$/.test(direction)
            ? this.step
            : 1;
        var multiplier = /^next/.test(direction)
            ? 1
            : -1;
        if (index >= 0) {
          index = index + step * multiplier
        }
        if (index < 0) {
          index = 0;
        } else if (index > ($items.length - 1)) {
          index = $items.length - 1;
        }

        this.selected = index

        const $parent = $(this.$refs.a_list),
            $element = $(items[index])

        let borderTop,
            paddingTop,
            offset,
            scroll,
            elementHeight,
            itemHeight;
        if ($parent.outerHeight() < $parent.prop("scrollHeight")) {
          borderTop = parseFloat($parent.css("borderTopWidth")) || 0;
          paddingTop = parseFloat($parent.css("paddingTop")) || 0;
          offset = $element.offset().top - $parent.offset().top - borderTop - paddingTop;
          scroll = $parent.scrollTop();
          elementHeight = $parent.height();
          itemHeight = $element.outerHeight();
          if (offset < 0) {
            $parent.scrollTop(scroll + offset);
          } else if (offset + itemHeight > elementHeight) {
            $parent.scrollTop(scroll + offset - elementHeight + itemHeight);
          }
        }
      }
    },
    _keyEvent(keyEvent, event) {
      if (this.open) {
        event.preventDefault();
        _move(keyEvent, event)
      }
    },
    onItemSelect(index) {
      this.handler(this.items[index])
      this.open = false
    },
    onKeypressKeydown(event) {
      switch (event.type) {
        case 'keydown':
          if (this.$attrs.readonly) {
            suppressKeyPress = true;
            suppressInput = true;
            suppressKeyPressRepeat = true;
            return;
          }
          suppressKeyPress = false;
          suppressInput = false;
          suppressKeyPressRepeat = false;
          switch (event.keyCode) {
            case keyCode.PAGE_UP:
              suppressKeyPress = true;
              _keyEvent("previousPage", event);
              break;
            case keyCode.PAGE_DOWN:
              suppressKeyPress = true;
              _keyEvent("nextPage", event);
              break;
            case keyCode.UP:
              suppressKeyPress = true;
              _keyEvent("previous", event);
              break;
            case keyCode.DOWN:
              suppressKeyPress = true;
              _keyEvent("next", event);
              break;
            case keyCode.ENTER:
              // // when menu is open and has focus
              // if (this.menu.active) {
              //
              //     // #6055 - Opera still allows the keypress to occur
              //     // which causes forms to submit
              suppressKeyPress = true;
              let $selected = $textInput.siblings('.js-autocomplete-container').find('.selected');
              setInputValue($textInput, $selected.text().trim(), $selected.attr('data-id'))
              event.preventDefault();
              //     this.menu.select(event);
              // }
              break;
            case keyCode.TAB:
              // if (this.menu.active) {
              //     this.menu.select(event);
              // }
              break;
            case keyCode.ESCAPE:
              $textInput.siblings('.js-autocomplete-container').hide()
              // if (this.menu.element.is(":visible")) {
              //     close(event);
              // Different browsers have different default behavior for escape
              // Single press can mean undo or clear
              // Double press in IE means clear the whole form
              event.preventDefault();
              // }
              break;
          }
          break;
        case 'keypress':
          if (suppressKeyPress) {
            suppressKeyPress = false;
            // if (this.menu.element.is(":visible")) {
            event.preventDefault();
            // }
            return;
          }
          if (suppressKeyPressRepeat) {
            return;
          }
          // Replicate some key handlers to allow them to repeat in Firefox and Opera
          switch (event.keyCode) {
            case keyCode.PAGE_UP:
              _keyEvent("previousPage", event);
              break;
            case keyCode.PAGE_DOWN:
              _keyEvent("nextPage", event);
              break;
            case keyCode.UP:
              _keyEvent("previous", event);
              break;
            case keyCode.DOWN:
              _keyEvent("next", event);
              break;
          }
      }
    },
    preFetch() {
      this.open = false
      if ((this.value || '').length >= this.charLimit) {
        this.fetch()
      }
      this.$emit('update:value', this.value)
    },
    fetch() {
      this.requestId = uniqId()
      const id = this.requestId + 0
      this.loading = true
      const params = {}
      const paramName = this.queryParam.length ? this.queryParam : 'query'
      params[paramName] = this.value
      this.request(
          {params: params, data: null},
          (result) => {
            if (id != this.requestId) return
            this.onAutocompleteResult(result)
          },
          (e) => {
            console.error(e)
          },
          () => {
            if (id == this.requestId) {
              this.loading = false
            }
          }
      )
    }
  },

}
</script>