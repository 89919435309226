import {createApp} from "@vue/runtime-dom";
import {addMaskDirective} from "../directive/mask";
import {createErrorStore} from "../utils";
import modalNewContract from './modal-new-contract'
import {EVENT_BINDING_REQUIRED} from "../events";

export default function ($document) {
    $document.on(EVENT_BINDING_REQUIRED, ()=>{
        if ($document.find('#modal-new-contract').not('[data-v-app]').length) {
            const app = createApp(modalNewContract, window.vueData);
            addMaskDirective(app)
            app.use(createErrorStore())
            app.mount('#modal-new-contract')
        }
    })
}