function buildTooltip(content) {
    return $(`<div class="tooltip-outer"><div class="tooltip">${content}</div></div>`)
}
export default function ($document) {
    $document
        .on('mouseenter', '[title]', function (e) {
            const $this = $(e.currentTarget);
            const title = $this.attr('title');
            if(!title) return;
            $this.removeAttr('title')
            const $tooltip = buildTooltip(title)
            $tooltip.appendTo('body')
            const positionX =  $this.offset().left;
            const positionY = $this.offset().top - $tooltip.outerHeight();
            $tooltip.css({left:positionX, top: positionY})
            $this.one('mouseleave', function () {
                setTimeout(function () {
                    $this.attr('title', title)
                    $tooltip.remove()
                }, 10)
            })
        })
}
