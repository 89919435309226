<template>
  <div class="form-control form-control-checkbox">
    <div class="label-title">{{ $attrs.label || '' }}</div>
    <label>
      <input
          type="checkbox"
          v-bind:checked=value
          @change="$emit('update:value', $event.target.checked)"
      />
      <span class="checkbox-span"></span>
      <span class="label-title">{{ $attrs.checkboxLabel || '' }}</span>
    </label>
  </div>
</template>
<script>
export default {
  props: {
    value: {
      type: Boolean, required: true
    }
  },
  emits: ['update:value']
}
</script>