<template>
  <div class="fieldset">
      <slot/>
  </div>
</template>
<script>
export default {
  props:{

  }
}
</script>