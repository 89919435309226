import {createApp} from "@vue/runtime-dom";
import list from './list.vue'
import {addMaskDirective} from "../directive/mask";
import {createStore} from "vuex";
import {createErrorStore} from "../utils";

export default function ($document) {
    if ($document.find('#vue-counterparty').length) {
        const app = createApp(list, window.vueData);
        addMaskDirective(app)
        app.use(createErrorStore())
        app.mount('#vue-counterparty')
    }
}