<template>
  <Card title="Основные данные">
    <Fieldset>
      <CheckboxControl
          checkboxLabel="Лизингополучатель"
          :value=value.roles.lessee
          @update:value="roleChanged('lessee', $event)"
      />
      <CheckboxControl
          checkboxLabel="Дилер"
          :value=value.roles.dealer
          @update:value="roleChanged('dealer', $event)"
      />
      <CheckboxControl
          checkboxLabel="Страховая компания"
          :value=value.roles.insurer
          @update:value="roleChanged('insurer', $event)"
      />
      <CheckboxControl
          checkboxLabel="Лизингодатель"
          :value=value.roles.lessor
          @update:value="roleChanged('lessor', $event)"
      />
    </Fieldset>
    <br>
    <Fieldset>
      <AutocompleteText
          :value=value.inn
          @update:value="propChanged('inn', $event)"
          @paste:value="onPasteInn($event)"
          label="ИНН*"
          class="inn"
          :url=fetchByInn
          :handler=onEntrySelected
          :lens="item=>`${item.inn} (${item.shortName})`"
          mask="inn"
          key=".inn"
      />
      <AutocompleteText
          :value=value.shortName
          @update:value="propChanged('shortName', $event)"
          label="Сокращённое наименование"
          class="short-name"
          :url="fetchByName"
          :handler=onEntrySelected
          :lens="item=>`${item.inn} (${item.shortName})`"
          key=".shortName"
      />
    </Fieldset>
    <br>
    <Fieldset>
      <TextControl
          :value=value.fullName
          class="latin-name"
          label="Полное наименование"
          :readonly=true
      />
    </Fieldset>
  </Card>
</template>
<style scoped>
.inn {
  width: 200px;
}

.short-name, .latin-name {
  flex-grow: 1;
}
</style>
<script>
import Card from "../components/Card";
import Fieldset from "../components/form/Fieldset";
import CheckboxControl from "../components/form/CheckboxControl";
import AutocompleteText from "../components/form/AutocompleteText";
import SelectControl from "../components/form/SelectControl";
import TextControl from "../components/form/TextControl";
import {debounceLatest} from "../utils";
import axios from "axios";

export default {
  components: {TextControl, SelectControl, AutocompleteText, CheckboxControl, Fieldset, Card},
  props: {
    value: Object,
    onEntrySelected: Function,
    fetchByInn: {type: String, required: true},
    fetchByName: {type: String, required: true}
  },
  emits: ['update:value'],
  methods: {
    getProps() {
      return {
        inn: this.value.inn,
        shortName: this.value.shortName,
        fullName: this.value.fullName
      }
    },
    getRoles() {
      return {
        lessor: this.value.roles.lessor,
        lessee: this.value.roles.lessee,
        insurer: this.value.roles.insurer,
        dealer: this.value.roles.dealer,
      }
    },
    propChanged(prop, value) {
      const props = this.getProps()
      props[prop] = value
      this.$emit('update:value', {...props, roles: this.getRoles()})
    },
    onPasteInn(value){
      this.propChanged('inn', value)

      if(value.length!=10) return

      const params = {
          query:value
      }
      const headers = {
        'Content-Type': 'application/json',
        'X-Requested-With': 'XMLHttpRequest'
      }
      axios
          .get(this.fetchByInn, {params:params, headers:headers})
          .then(result=>{
            const data = result.data.items
            if(data.length>0){
              this.onEntrySelected(data[0])
            }
          })
          .catch(e=>console.error(e))
    },
    roleChanged(role, value) {
      const roles = this.getRoles()
      roles[role] = value
      this.$emit('update:value', {...this.getProps(), roles: roles})
    }
  }
}
</script>