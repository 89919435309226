import {v4 as uuidv4} from 'uuid';
import qs from 'qs'
import {createStore} from "vuex";

export const uniqId = (function () {
    let i = 0;
    return function () {
        return i++;
    }
})();

export const uuid = () => uuidv4()

export const validateObject = (obj, keys) => {
    const objKeys = Object.keys(obj)
    return keys.filter(key => objKeys.indexOf(key) < 0)
}

export const debounceLatest = (runnable, delay) => {
    let _timeout = -1;
    return (...args) => {
        clearTimeout(_timeout)
        _timeout = setTimeout(() => {
            runnable.apply(this, args)
        }, delay)
    }
}
export const buildQuery = (url, params) => {
    return url + '?' + qs.stringify(params)
}

export const createErrorStore = () => {
    return createStore({
        strict: process.env.NODE_ENV !== 'production',
        devtools: process.env.NODE_ENV !== 'production',
        state() {
            return {
                errors: []
            }
        },
        mutations: {
            addError(state, {key, error}) {
                state.errors.push({key, error})
            },
            dropError(state, key) {
                state.errors = state.errors.filter(e => key !== e.key)
            },
            dropAllErrors(state) {
                state.errors = []
            }
        }
    })
}