<template>
	<teleport to=".main-column>.content">
		<div class="panel-outer" v-bind:style="outerStyle">
			<div class="panel-inner" v-bind:style="innerStyle" ref="inner">
				<div class="panel-content">
					<slot/>
				</div>
			</div>
		</div>
	</teleport>
</template>
<script>
  const menu = () => document.querySelector('.left-menu')
  const measureWidth = (ref) => ref.getBoundingClientRect().width
  const measureHeight = (ref) => ref.getBoundingClientRect().height
  export default {
    props: {},
    data() {
      return {
        width: 0,
        height: 0,
        menuObserver: null,
        heightObserver: null,
      }
    },
    mounted() {
      const observer = new ResizeObserver(() => {
        this.width = measureWidth(menu())
      })
      observer.observe(menu())
      this.menuObserver = observer

      const observer2 = new ResizeObserver(() => {
        this.height = measureHeight(this.$refs.inner)
      })
      observer2.observe(this.$refs.inner)
      this.heightObserver = observer2

      this.width = measureWidth(menu())
      this.height = measureHeight(this.$refs.inner)
    },
    beforeUnmount() {
      if (this.menuObserver) this.menuObserver.unobserve(menu())
      if (this.heightObserver) this.heightObserver.unobserve(this.$refs.inner)
    },

    computed: {
      innerStyle() {
        return {
          left: `${this.width}px`
        }
      },
      outerStyle() {
        return {
          height: `${this.height}px`
        }
      },
    }
  }
</script>