import Inputmask from "inputmask";
import {EVENT_BINDING_REQUIRED} from "../../js/events";
import {isNumber} from "../../js/functions";

export default function ($document) {
    $document.on(EVENT_BINDING_REQUIRED, function (e) {
        const $root = $(e.target);
        $root.find('.js-mask-money').addBack('.js-mask-money').each(function (i, v) {
            const $el = $(v);
            const im = new Inputmask("decimal", {
                digits: 2,
                radixPoint: ',',
                groupSeparator: ' ',
                min: $el.attr('min') || 0,
                SetMaxOnOverflow: true,
                max: $el.attr('max') || 1000000000000,
                inputtype: "text"
            });
            v._plugin_mask = im;
            im.mask(v);
        })
        $root.find('.js-mask-currency').addBack('.js-mask-currency').each(function (i, v) {
            const im = new Inputmask("decimal", {
                digits: 4,
                radixPoint: ',',
                groupSeparator: ' ',
                min: 0,
                inputtype: "text"
            });
            v._plugin_mask = im;
            im.mask(v);
        })
        $root.find('.js-mask-percent').addBack('.js-mask-percent').each(function (i, v) {
            const im = new Inputmask("decimal", {
                digits: 2,
                radixPoint: ',',
                SetMaxOnOverflow: true,
                groupSeparator: ' ',
                min: 0,
                max: $(v).attr('max') || 100,
                inputtype: "text"
            });
            v._plugin_mask = im;
            im.mask(v);
        })
    })
}

export function cleanNumericValue($input) {
    return Number(("" + ($input.val() || 0))
        .split("")
        .filter((v) => v !== " ")
        .map((v) => v === "," ? "." : v)
        .join("")
    )
}

export function setValueAndMaskIt($input, value) {
    if (isNumber(value)) {
        value = Math.round(value * 10000) / 10000
    }
    setTimeout(function () {
        $input.val(value);
        $input.trigger('setvalue', [value]);
    }, 1)
}

export const MASK_INN = 'inn';
export const MASK_PHONE = 'phone';
export const MASK_EMAIL = 'email';
export const MASK_DATE = 'date';

export function maskByCode(input, mask) {
    let inputMask = null
    switch (mask) {
        case MASK_INN:
            inputMask = new Inputmask("decimal", {
                digits: 2,
                radixPoint: ',',
                SetMaxOnOverflow: true,
                groupSeparator: ' ',
                min: 0,
                max: $(input).attr('max') || 100,
                inputtype: "text"
            });
            break;
        default:
            return;
    }
    input._plugin_mask = inputMask;
    inputMask.mask(input);
}

export function refreshMask($root) {
    $root.find('.js-mask-money').addBack('.js-mask-money').each(function (i, v) {
        const $el = $(v);
        const im = new Inputmask("decimal", {
            digits: 2,
            radixPoint: ',',
            groupSeparator: ' ',
            min: $el.attr('min') || 0,
            SetMaxOnOverflow: true,
            max: $el.attr('max') || 1000000000000,
            inputtype: "text"
        });
        v._plugin_mask = im;
        im.mask(v);
    })
    $root.find('.js-mask-currency').addBack('.js-mask-currency').each(function (i, v) {
        const im = new Inputmask("decimal", {
            digits: 4,
            radixPoint: ',',
            groupSeparator: ' ',
            min: 0,
            inputtype: "text"
        });
        v._plugin_mask = im;
        im.mask(v);
    })
    $root.find('.js-mask-percent').addBack('.js-mask-percent').each(function (i, v) {
        const im = new Inputmask("decimal", {
            digits: 2,
            radixPoint: ',',
            SetMaxOnOverflow: true,
            groupSeparator: ' ',
            min: 0,
            max: $(v).attr('max') || 100,
            inputtype: "text"
        });
        v._plugin_mask = im;
        im.mask(v);
    })
}
