<template>
  <AjaxTable>
    <template v-slot:form>asd</template>
    <template v-slot:table>asd2</template>
  </AjaxTable>
</template>
<script>
import AjaxTable from "../components/AjaxTable";
export default {
  components: {AjaxTable},
  props:{}
}
</script>