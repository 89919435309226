import { render } from "./TextControl.vue?vue&type=template&id=0a883ab1"
import script from "./TextControl.vue?vue&type=script&lang=js"
export * from "./TextControl.vue?vue&type=script&lang=js"
script.render = render
/* hot reload */
if (module.hot) {
  script.__hmrId = "0a883ab1"
  const api = __VUE_HMR_RUNTIME__
  module.hot.accept()
  if (!api.createRecord('0a883ab1', script)) {
    api.reload('0a883ab1', script)
  }
  
  module.hot.accept("./TextControl.vue?vue&type=template&id=0a883ab1", () => {
    api.rerender('0a883ab1', render)
  })

}

script.__file = "src/js/components/form/TextControl.vue"

export default script