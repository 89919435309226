import {EVENT_BINDING_REQUIRED} from "../../../../js/events";
import $ from 'jquery';
import "air-datepicker"
const dateDecoder = function (key, value) {
    if(key == "minDate" || key == "maxDate"){
        return new Date(value);
    }
    return value;
}
/**
 * выбор дейтпикера основывается на нескольких ограничениях:
 * - должна быть поддержка клавиатуры, т.е. accessibility (отсекает много наколеночных "легких" решений)
 * - дейтпикер не должен модифицировать (добавлять-изменять) id атрибут элемента <input/>;
 * к сожалению jquery-ui datepicker это делает поэтому он отсекается.
 *
 * настройка дейтпикера пусть будет от сервера через дата-атрибут.
 * @param $document
 */
export default function ($document) {
    $.fn.datepicker.language['ru'] =  {
        days: ['Воскресенье','Понедельник','Вторник','Среда','Четверг','Пятница','Суббота'],
        daysShort: ['Вос','Пон','Вто','Сре','Чет','Пят','Суб'],
        daysMin: ['Вс','Пн','Вт','Ср','Чт','Пт','Сб'],
        months: ['январь','февраль','март','апрель','май','июнь','июль','август','сентябрь','октябрь','ноябрь','декабрь'],
        monthsShort: ['янв','фев','мар','апр','май','июн','июл','авг','сен','окт','ноя','дек'],
        today: 'Сегодня',
        clear: 'Очистить',
        dateFormat: 'dd.mm.yyyy',
        timeFormat: 'hh:ii',
        firstDay: 1
    };
    $document.on(EVENT_BINDING_REQUIRED, function (e) {
        $(e.target).find('.js-datepicker').each(function (i,v) {
            let $input = $(v);
            let options = JSON.parse($input.attr('data-datepicker-options') || "{}", dateDecoder)
            options.prevHtml = '<svg class="svg-icon svg-icon-datepicker-prev"><use xlink:href="/img/svg-sprite.svg#svg-icon-datepicker-prev"></use></svg>';
            options.nextHtml = '<svg class="svg-icon svg-icon-datepicker-next"><use xlink:href="/img/svg-sprite.svg#svg-icon-datepicker-next"></use></svg>';
            options.offset=0;
            options.autoClose=true;
            options.onSelect = function(formattedDate, date, datepicker){
                datepicker.$el.trigger('change')
            }
            $input.datepicker(options);
        })
    })
}
