<template>
  <div>
    <Fieldset>
      <TextControl
          :value=value.country
          @update:value="onPropChange('country', $event)"
          class="country"
          label="Страна"
          :readonly="true"
      />
      <AutocompleteText
          :value="value.zip"
          @update:value="onPropChange('zip', $event)"
          :handler="onSelectNewAddress" :lens="zipLens" :url="autocompleteUrlWithParams"
          class="zip"
          label="Индекс"
      />
      <AutocompleteText
          :value="value.region"
          @update:value="onPropChange('region', $event)"
          :handler="onSelectNewAddress" :lens="regionLens" :url="autocompleteUrlWithParams"
          class="region"
          label="Регион"
      />
    </Fieldset>
    <Fieldset>
      <AutocompleteText
          :value="value.area"
          @update:value="onPropChange('area', $event)"
          :handler="onSelectNewAddress" :lens="areaLens" :url="autocompleteUrlWithParams"
          label="Район"
          class="area"/>
      <AutocompleteText
          :value="value.city"
          @update:value="onPropChange('city', $event)"
          :handler="onSelectNewAddress" :lens="cityLens" :url="autocompleteUrlWithParams"
          label="Город"
          class="city"/>
    </Fieldset>
    <Fieldset>
      <AutocompleteText
          :value="value.settlement"
          @update:value="onPropChange('settlement', $event)"
          :handler="onSelectNewAddress" :lens="settlementLens" :url="autocompleteUrlWithParams"
          label="Населенный пункт"
          class="settlement"/>
      <AutocompleteText
          :value="value.street"
          @update:value="onPropChange('street', $event)"
          :handler="onSelectNewAddress" :lens="streetLens" :url="autocompleteUrlWithParams"
          label="Улица"
          class="street"/>
    </Fieldset>
    <Fieldset class="row">
      <div class="combined form-control">
        <TextControl
            :value="value.houseType"
            label="Тип здания"
            @update:value="onPropChange('houseType', $event)"
            class="house-type"
        />
        <TextControl
            :value="value.house"
            @update:value="onPropChange('house', $event)"
            class="house"
        />
      </div>
      <div class="combined form-control">
        <TextControl
            :value="value.blockType"
            label="Тип блока"
            @update:value="onPropChange('blockType', $event)"
            class="block-type"
        />
        <TextControl
            :value="value.block"
            @update:value="onPropChange('block', $event)"
            class="block"
        />
      </div>
      <div class="combined form-control">
        <TextControl
            :value="value.flatType"
            label="Тип помещения"
            @update:value="onPropChange('flatType', $event)"
            class="flat-type"
        />
        <TextControl
            :value="value.flat"
            @update:value="onPropChange('flat', $event)"
            class="flat"
        />
      </div>
    </Fieldset>
    <Fieldset>
      <TextareaControl
          :value="value.presentation"
          @update:value="onPropChange('presentation', $event)"
          label="Представление"
      />
    </Fieldset>
    <Fieldset>
      <TextareaControl
          :value="value.comment"
          @update:value="onPropChange('comment', $event)"
          label="Комментарий"
      />
    </Fieldset>
  </div>
</template>
<style scoped>
.country {
  flex-grow: 1;
}

.zip {
  width: 120px;
}

.region, .area, .city, .settlement, .street {
  width: 50%;
}

.combined {
  width: 33.3333333%;
  display: flex;
  flex-direction: row;
}

.flat-type, .block-type, .house-type {
  flex-grow: 1;
}

.flat, .block, .house {
  width: 75px;
}
</style>
<script>
import SearchableSelect from "../components/form/SearchableSelect.vue";
import TextControl from "../components/form/TextControl.vue";
import TextareaControl from "../components/form/TextareaControl.vue";
import Fieldset from "../components/form/Fieldset";
import SelectControl from "../components/form/SelectControl";
import AutocompleteText from "../components/form/AutocompleteText";
import {buildQuery, validateObject} from "../utils";

const locations = [
  'country',
  'region',
  'area',
  'city',
  'settlement',
  'street',
];
export default {
  props: {
    value: {
      type: Object, validator: function (value) {
        const missing = validateObject(value, [
          'country',
          'zip',
          'region',
          'area',
          'city',
          'settlement',
          'street',
          'houseType',
          'house',
          'blockType',
          'block',
          'flatType',
          'flat',
          'presentation',
          'comment',
        ])
        if (missing.length) {
          console.error(`missing keys: [${missing.join(',')}]`)
        }
        return missing.length === 0
      }
    },
    autocompleteUrl: {type: String, required: true},
    addressSummary: Function
  },
  emits: ['update:value'],
  computed:{
    autocompleteUrlWithParams(){
      const props = this.getProps();
      const params = {};
      ['region', 'area', 'city', 'settlement', 'street'].forEach(param=>{
        if(null!==props[param]){
          params[param] = props[param]
        }
      })
      return buildQuery(this.autocompleteUrl, {locations:params})
    },
  },
  methods: {

    onSelectNewAddress(address) {
      this.$emit('update:value', {...address, presentation: this.addressSummary(address), comment: this.value.comment})
    },
    getParentScope(addressPropType) {
      return parents.slice(0, parents.indexOf(addressPropType))
    },
    zipLens(object) {
      return this.addressSummary({
        zip:object.zip,
        region:object.region,
        area:object.area
      })
    },
    regionLens(object) {
      return object['region']
    },
    areaLens(object) {
      return object['area']
    },
    cityLens(object) {
      return object['city']
    },
    settlementLens(object) {
      return object['settlement']
    },
    streetLens(object) {
      return object['street']
    },
    onPropChange(prop, value) {
      const props = this.getProps()
      props[prop] = value
      if ('presentation' !== prop && 'comment' !== prop) {
        props.presentation = this.addressSummary(props)
      }
      this.$emit('update:value', props)
    },
    getProps() {
      return {
        country: this.value.country,
        zip: this.value.zip,
        region: this.value.region,
        area: this.value.area,
        city: this.value.city,
        settlement: this.value.settlement,
        street: this.value.street,
        houseType: this.value.houseType,
        house: this.value.house,
        blockType: this.value.blockType,
        block: this.value.block,
        flatType: this.value.flatType,
        flat: this.value.flat,
        presentation: this.value.presentation,
        comment: this.value.comment
      }
    }
  },
  components: {AutocompleteText, SelectControl, Fieldset, TextareaControl, SearchableSelect, TextControl}
}
</script>