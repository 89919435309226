import parsley                  from 'parsleyjs'
import {EVENT_BINDING_REQUIRED} from "../../js/events";

export function initValidator($document, formSelector, namespace) {
    // расширяем стандартный апи чтобы вызывать ошибки у полей
    jQuery.fn.extend({
        // ошибки одного поля. удаление ошибок делает парслей
        showFormFieldErrors: function (errors) {
            if (this.length && this.parsley()) {
                this.removeFieldErrors()
                if (!$.isArray(errors)) {
                    errors = [errors]
                }
                errors.forEach(error => {
                    this
                        .parsley()
                        .addError('customValidationId', {message: error})
                });
            }
            return this
        }, // ошибки ко всей форме. удаление ошибок руками в removeFormErrors
        showFormErrors: function (errors) {
            var $form = this;
            $form.removeFormErrors()
            var $errorContainer = $form.find('.error-description');
            if (!$errorContainer.length) {
                $errorContainer = $('<div/>', {
                    class: 'error-description'
                }).appendTo($form)
            }
            if (!$.isArray(errors)) {
                errors = [errors]
            }
            $errorContainer.show().html(errors.join("<br/>"))
            return this
        },
        removeFieldErrors: function () {
            if (this.length && this.parsley()) {
                this.parsley().removeError('customValidationId');
            }
            this.closest('.form-control').find('.parsley-errors-list').remove()
            return this;
        },
        removeFormErrors: function () {
            var $form = $(this);
            $form.find('.error-description').empty();
            return this;
        }
    });
    var Parsley = window.Parsley;
    Parsley.addMessages('ru', {
        defaultMessage: "Некорректное значение.",
        type: {
            email: "Введите адрес электронной почты.",
            url: "Введите URL адрес.",
            number: "Введите число.",
            integer: "Введите целое число.",
            digits: "Введите только цифры.",
            alphanum: "Введите буквенно-цифровое значение."
        },
        notblank: "Это поле должно быть заполнено.",
        required: "Обязательное поле.",
        pattern: "Это значение некорректно.",
        min: "Это значение должно быть не менее чем %s.",
        max: "Это значение должно быть не более чем %s.",
        range: "Это значение должно быть от %s до %s.",
        minlength: "Это значение должно содержать не менее %s символов.",
        maxlength: "Это значение должно содержать не более %s символов.",
        length: "Это значение должно содержать от %s до %s символов.",
        mincheck: "Выберите не менее %s значений.",
        maxcheck: "Выберите не более %s значений.",
        check: "Выберите от %s до %s значений.",
        equalto: "Это значение должно совпадать."
    });
    Parsley.setLocale('ru');
    Parsley.options.namespace = namespace;
    Parsley.options.trigger = 'submit';
    Parsley.options.triggerAfterFailure = 'input change';
    Parsley.options.errorClass = 'error';
    Parsley.options.successClass = 'success';
    Parsley.options.excluded = 'input[type=button], input[type=submit], input[type=reset], input[type=hidden], [disabled]'
    Parsley.options.classHandler = el => $(el.element).closest('.form-control')
    Parsley.options.errorsContainer = el => $(el.element).closest('.form-control')
    $document
        .on('change input', `${formSelector} :input`, function () {
            // при вводе в поле удаляем у него ошибку валидации
            $(this).removeFieldErrors()
        })
        // биндинг валидатора
        .on(EVENT_BINDING_REQUIRED, function (e) {
            $(e.target)
                .find(formSelector)
                .addBack(formSelector)
                .parsley()
        });
}

/**
 *
 * @param $form
 * @returns Promise
 */
export function validate($form) {
    return $form
        .removeFormErrors()
        .parsley()
        .whenValidate()
}
