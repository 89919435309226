<template>
  <div class="modal-container">
    <div class="modal-title" v-if="title.length>0">{{ title }}</div>
    <slot/>
  </div>
</template>
<script>
export default {
  props: {
    title: {
      type: String,
      required: false
    }
  }
}
</script>