import {EVENT_BINDING_REQUIRED}            from "../../../js/events";
import Choices                             from "choices.js";
import {highlightString, toggleValueClass} from "../../../js/functions";

const options = {
    silent: false,
    renderChoiceLimit: -1,
    addItems: true,
    removeItems: false,
    removeItemButton: false,
    editItems: false,
    duplicateItemsAllowed: true,
    delimiter: ',',
    paste: false,
    searchEnabled: false,
    searchChoices: false,
    position: 'auto',
    shouldSort: false,
    shouldSortItems: false,
    placeholder: null,
    searchPlaceholderValue: null,
    prependValue: null,
    appendValue: null,
    renderSelectedChoices: 'always',
    loadingText: 'Загрузка...',
    noResultsText: 'Совпадений не найдено',
    noChoicesText: 'Нет вариантов',
    itemSelectText: "",
    addItemText: (value) => {
        return `Press Enter to add <b>"${value}"</b>`;
    },
    maxItemText: (maxItemCount) => {
        return `Only ${maxItemCount} values can be added`;
    },
    valueComparer: (value1, value2) => {
        return value1 === value2;
    },
    classNames: {
        containerOuter: 'select-outer',
        containerInner: 'select-inner',
        input: 'select-input',
        inputCloned: 'select-input-cloned',
        list: 'select-list',
        listItems: 'select-list-multiple',
        listSingle: 'select-list-single',
        listDropdown: 'select-list-dropdown',
        item: 'select-item',
        itemSelectable: 'select-item-selectable',
        itemDisabled: 'select-item-disabled',
        itemChoice: 'select-item-choice',
        placeholder: 'select-placeholder',
        group: 'select-group',
        groupHeading: 'select-heading',
        button: 'select-button',
        activeState: 'is-active',
        focusState: 'is-focused',
        openState: 'is-opened',
        disabledState: 'is-disabled',
        highlightedState: 'hover',
        selectedState: 'is-selected',
        flippedState: 'is-flipped',
        loadingState: 'is-loading',
        noResults: 'has-no-results',
        noselect: 'has-no-select'
    }, // Choices uses the great Fuse library for searching. You
    // can find more options here: https://github.com/krisk/Fuse#options
    fuseOptions: {
        includeScore: true,
        includeMatches:true,
        threshold:1,
        ignoreLocation: true
    },
    callbackOnInit: function () {
        this.containerInner.element.appendChild($('<svg class="svg-icon svg-icon-dropdown"><use xlink:href="/img/svg-sprite.svg#svg-icon-dropdown"></use></svg>').get(0))
        $(this.containerOuter.element).on('focus blur', function (e) {
            $(this).closest('.form-control').toggleClass('focus', e.type === 'focus')
        })
    },
    // можно было бы передавать стрелочную функционую но у нее не было бы this, который нужен
    // а еще забавно что несколько классов не добавить через настройки, только через темплейты, что за фейспалм...
    callbackOnCreateTemplates: function(template) {
        return {
            input({ input, inputCloned }, placeholderValue) {
                const inp = Object.assign(document.createElement('input'), {
                    type: 'text',
                    className: `${input} ${inputCloned} no-label-interaction`,
                    autocomplete: 'off',
                    autocapitalize: 'off',
                    spellcheck: false,
                });

                inp.setAttribute('role', 'textbox');
                inp.setAttribute('aria-autocomplete', 'list');
                inp.setAttribute('aria-label', placeholderValue);

                return inp;
            },
            choiceList({list}, isSelectOneElement) {
                const div = Object.assign(document.createElement('div'), {
                    className: list + " js-custom-scrollbar",
                });
                if (!isSelectOneElement) {
                    div.setAttribute('aria-multiselectable', 'true');
                }
                div.setAttribute('role', 'listbox');
                return div;
            },
            choice(
                {
                    item,
                    itemChoice,
                    itemSelectable,
                    selectedState,
                    itemDisabled,
                    placeholder,
                },
                {
                    id,
                    value,
                    label,
                    groupId,
                    elementId,
                    disabled: isDisabled,
                    selected: isSelected,
                    placeholder: isPlaceholder,
                },
                selectText,
            ) {
                const div = Object.assign(document.createElement('div'), {
                    id: elementId,
                    innerHTML: this._isSearching
                               ? highlightString(
                                   label,
                                    this._currentValue
                                    .split(" ")
                                    .map(str => str.trim())
                                    .filter(str => str.length > 0)
                                )
                               : label,
                    className: `${item} ${itemChoice}`,
                });

                div.innerHTML = this._isSearching
                    ? highlightString(
                        label,
                        this._currentValue
                            .split(" ")
                            .map(str => str.trim())
                            .filter(str => str.length > 0)
                    )
                    : label

                if (this._isSelectMultipleElement) {
                    // для множественного выбора рендерятся галочки

                    div.innerHTML = isSelected
                        ? `<label><input checked type="checkbox"><div class="checkbox-span" data-value="${value}"></div><div class="label-title">${div.innerHTML}</div></label>`
                        : `<label><input type="checkbox"><div class="checkbox-span" data-value="${value}"></div><div class="label-title">${div.innerHTML}</div></label>`
                }

                if (isSelected) {
                    div.classList.add(selectedState);
                    div.classList.add('chosen');
                }

                if (isPlaceholder) {
                    div.classList.add(placeholder);
                }

                div.setAttribute('role', groupId > 0 ? 'treeitem' : 'option');

                Object.assign(div.dataset, {
                    choice: '',
                    id,
                    value,
                    selectText,
                });

                if (isDisabled) {
                    div.classList.add(itemDisabled);
                    div.dataset.choiceDisabled = '';
                    div.setAttribute('aria-disabled', 'true');
                } else {
                    div.classList.add(itemSelectable);
                    div.dataset.choiceSelectable = '';
                }

                return div;
            }
        }
    }
}
export function enableSelect(select) {
    select._plugin_choices.enable();
}
export function disableSelect(select) {
    select._plugin_choices.disable()
    $(select).removeAttr('disabled')
}
export function setValue(select, value) {
    select._plugin_choices.setChoiceByValue(value)
}
export default function ($document) {
    $document
        .on(EVENT_BINDING_REQUIRED, function (e) {
            $(e.target).find('select').each(function (i, v) {
                const $select = $(v);
                let $chosenContainer = $({});
                let factory = "";
                const $parent = $select.closest('.form-control');
                let opts = $.extend(true, {}, options);
                if($select.is('.searchable')){
                    opts.searchEnabled = true;
                    opts.searchChoices = true;
                    opts.searchResultLimit = 100;
                    if($select.is('[data-fuse-opts]')){
                        try {
                            opts.fuseOptions = {...opts.fuseOptions, ...JSON.parse($select.attr('data-fuse-opts'))}
                        }catch (err){
                            // noop
                            console.error('malformed Fuse library options, json expected')
                            console.error(err)
                        }
                    }
                }
                if($select.is('[data-noResultsText')){
                    opts.noResultsText = $select.attr('data-noResultsText')
                }
                if($select.is('[multiple]')){
                    opts.removeItemButton = true;
                    opts.duplicateItemsAllowed = false;
                    opts.paste = false;
                    opts.resetScrollPosition = false;
                    opts.renderSelectedChoices = 'always';
                    $chosenContainer = $($select.attr('data-chosen-container'))
                    factory = $select.attr('data-factory-callback')
                }
                $chosenContainer.on('click', '[data-delete]', function (){
                    let $item = $(this).closest('[data-value]')
                    choices.removeActiveItemsByValue($item.attr('data-value'))
                    $(choices.passedElement.element).trigger('change')
                })
                const choices = new Choices(v, opts);
                $parent
                    .on('keydown', function (e) {
                        if (e.which === $.ui.keyCode.SPACE) {
                            if($(e.target).is('.select-input')) return;
                            e.preventDefault()
                        }
                    })
                    .on('showDropdown', function () {
                        // повторная инициализация прокрутки на выпадающем списке
                        // $(choices.choiceList.element).trigger(EVENT_BINDING_REQUIRED)
                    })
                    .on('addItem removeItem', function (e){
                        const event = e.originalEvent.detail
                        const {value, label} = event;
                        if ('addItem' == e.type) {
                            if (window.hasOwnProperty(factory) && typeof window[factory] == 'function') {
                                let $entry = window[factory].call(null, value, label);
                                $entry.attr('data-value', value);
                                $chosenContainer.append($entry)
                            }
                        } else {
                            $chosenContainer.find(`[data-value=${value}]`).remove()
                        }
                    })
                toggleValueClass($select, $parent)
                v._plugin_choices = choices
                if($parent.hasClass('readonly')){
                    disableSelect(v)
                }
            })
        })
        .on('change', 'select', function (e) {
            toggleValueClass($(e.target), $(e.target).closest('.form-control'))
        })

    window.createChosen = function (value, label){
        return $('<div/>',{
            class:'chosen-item',
            html:[
                $('<span data-delete><svg class="svg-icon svg-icon-cross-small"><use xlink:href="/img/svg-sprite.svg#svg-icon-cross-small"></use></svg></span>'),
                $('<span/>',{text:label}),
                $('<input/>',{'type':'hidden', value:value})
            ]
        })
    }
}
export function replaceOptions(select, options){
    let data = [];
    for (let i in options){
        if(options.hasOwnProperty(i)){
            data.push({label:i, value:options[i]})
        }
    }
    select._plugin_choices.setChoices(
        data,
        'value',
        'label',
        true
    )
}