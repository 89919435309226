<template>
  <div v-bind:class="['requisite-outer', {'selected':value.main}]">
    <Fieldset>
      <AutocompleteText
          :value="value.bik"
          :handler="onBankSelect"
          :lens="item=>`${item.bik} ${item.bank}`"
          :url="autocompleteUrl"
          class="bik"
          @update:value="onPropChange('bik', $event)"
          @paste:value="onPasteBik"
          label="БИК"
      />
      <AutocompleteText
          :value="value.bank"
          :handler="onBankSelect"
          :lens="item=>`${item.bik} ${item.bank}`"
          :url="autocompleteUrl"
          class="bank"
          @update:value="onPropChange('bank', $event)"
          label="Банк"
      />
      <TextControl :value="value.corr" class="corr" @update:value="onPropChange('corr', $event)" label="Кор. счёт"/>
      <TextControl :value="value.account" class="account" @update:value="onPropChange('account', $event)"
                   label="Номер счёта"/>
    </Fieldset>
    <Fieldset>
      <RadioControl
          class="is-main"
          :value="isMain"
          @update:value="setMain"
          :radios="[{value:'main',label:'Основной счет'}]"
          name="requisite-main"
      />
      <a @click="onDelete">
        <SvgIcon name="trash"/>
      </a>
    </Fieldset>
  </div>
</template>
<style scoped>
.selected:after {
  background: #F2F7FC;
}

.is-main {
  flex-grow: 1;
}

.requisite-outer {
  position: relative;
  padding-top: 20px;
}

.requisite-outer:after {
  content: "";
  display: block;
  position: absolute;
  z-index: -1;
  left: -20px;
  right: -20px;
  top: 0;
  bottom: 0;
}

.bik, .corr, .account {
  width: 200px;
}

.bank {
  flex-grow: 1;
}
</style>
<script>
import TextControl from "../components/form/TextControl";
import SelectControl from "../components/form/SelectControl";
import SvgIcon from "../components/SvgIcon";
import Fieldset from "../components/form/Fieldset";
import RadioControl from "../components/form/RadioControl";
import AutocompleteText from "../components/form/AutocompleteText";
import axios from "axios";

export default {
  props: {
    value: {type: Object, required: true},
    onDelete: Function,
    autocompleteUrl: {type: String, required: true}
  },
  computed: {
    isMain() {
      return this.value.main ? 'main' : ''
    }
  },
  methods: {
    setMain(value) {
      this.onPropChange('main', 'main' === value)
    },
    onBankSelect(entry){
      this.$emit('update:value',  {
        guid: this.value.guid,
        bik: entry.bik,
        bank: entry.bank,
        corr: entry.corr,
        account: this.value.account,
        main: this.value.main
      })
    },
    getProps() {
      return {
        guid: this.value.guid,
        bik: this.value.bik,
        bank: this.value.bank,
        corr: this.value.corr,
        account: this.value.account,
        main: this.value.main
      }
    },
    onPropChange(prop, value) {
      const props = this.getProps()
      props[prop] = value
      this.$emit('update:value', props)
    },
    onPasteBik(bik){
        this.onPropChange('bik', bik)
        const params = {
          query:bik
        }
        const headers = {
          'Content-Type': 'application/json',
          'X-Requested-With': 'XMLHttpRequest'
        }
        axios
            .get(this.autocompleteUrl, {params:params, headers:headers})
            .then(result=>{
              const data = result.data.items
              if(data.length>0){
                this.onBankSelect(data[0])
              }
            })
            .catch(e=>console.error(e))
    }
  },
  emits: ['update:value'],
  components: {AutocompleteText, RadioControl, Fieldset, SvgIcon, SelectControl, TextControl}
}
</script>