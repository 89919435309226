<template>
  <div>
    <slot name="form"></slot>
    <slot name="table"></slot>
  </div>
</template>
<script>
export default {
  props:{}
}
</script>