import {mapMutations, mapState} from "vuex";

export const errorSupport = {
    watch: {
        value: {
            handler: function (n, o) {
                const key = this.$.vnode.key
                if (!key) return []
                this.dropError(key)
            }
        }
    },
    methods:{
        ...mapMutations(['dropError'])
    },
    computed: {
        ...mapState({
            errors(state) {
                const key = this.$.vnode.key
                if (!key) return []
                return state.errors.filter(e => key == e.key).map(e => e.error)
            }
        })
    },
}