import {EVENT_BINDING_REQUIRED} from "../events";
import {createApp} from "@vue/runtime-dom";
import {addMaskDirective} from "../directive/mask";
import {createErrorStore} from "../utils";
import TabContract from "./TabContract";

export default function ($document) {
   if ($document.find('#vue-tab-contract').not('[data-v-app]').length) {
        const app = createApp(TabContract, window.vueData);
        addMaskDirective(app)
        app.use(createErrorStore())
        app.mount('#vue-tab-contract')
    }
}