<template>
	<a class="button button-outline2"
	   v-bind:data-fancybox=href
	   v-bind:data-src=href
	   v-bind:href=href
	   data-type="ajax"
	   v-bind:title="$attrs.title||''">
		<SvgIcon :name=name></SvgIcon>
	</a>
</template>
<script>
  import SvgIcon from "./SvgIcon";

  export default {
    components: {SvgIcon},
    props: {
      href: String,
      name: String
    }
  }
</script>