<template>
	<div class="signer">
		<div class="entity-head">
			<div class="h4">{{title}}</div>
			<a @click="onDelete">Удалить</a>
		</div>
		<Fieldset>
			<TextControl
				:value="value.lastName"
				@update:value="onPropChange('lastName', $event)"
				class="one-third"
				label="Фамилия"
			/>
			<TextControl
				:value="value.initials"
				@update:value="onPropChange('initials', $event)"
				class="one-third"
				label="Инициалы"
			/>
			<TextControl
				:value="value.job"
				@update:value="onPropChange('job', $event)"
				class="one-third"
				label="Должность"
			/>
		</Fieldset>
		<Fieldset>
			<RadioControl
				:value="value.level"
				@update:value="onPropChange('level', $event)"
				:radios="radios"
				class="one-third"
				label="Уровень подписанта"
			/>
			<SelectControl
				:value="value.basis"
				@update:value="onPropChange('basis', $event)"
				:options="options"
				label="Основание"
				class="basis"
			/>
			<TextControl :value="value.number" @update:value="onPropChange('number', $event)" label="Номер" class="number"/>
			<DateControl :value="value.date" @update:value="onPropChange('date', $event)" label="Дата" class="date"/>
		</Fieldset>
	</div>
</template>
<style scoped>
	.one-third {
		width: 33.333333%;
	}

	.basis {
		flex-grow: 1;
	}

	.number, .date {
		width: 130px;
	}
</style>
<script>
  import TextControl from "../components/form/TextControl";
  import SelectControl from "../components/form/SelectControl";
  import Fieldset from "../components/form/Fieldset";
  import RadioControl from "../components/form/RadioControl";
  import DateControl from "../components/form/DateControl";

  export default {
    props: {
      title: {type: String, required: true},
      value: {type: Object, required: true},
      options: {type: Object, required: true},
      onDelete: {type: Function, required: true}
    },
    emits: ['update:value'],
    computed: {
      radios() {
        return [
          {label: 'Все документы', value: 'all'},
          {label: 'Только АКТы', value: 'act'}
        ]
      }
    },
    methods: {
      getProps() {
        return {
          lastName: this.value.lastName,
          initials: this.value.initials,
          job: this.value.job,
          level: this.value.level,
          basis: this.value.basis,
          number: this.value.number,
          date: this.value.date,
          guid: this.value.guid
        }
      },
      onPropChange(prop, value) {
        const props = this.getProps()
        props[prop] = value
        this.$emit('update:value', props)
      }
    },
    components: {DateControl, RadioControl, Fieldset, SelectControl, TextControl}
  }
</script>