import svg4everybody             from 'svg4everybody'
import feather                   from 'feather-icons';
import fancybox                  from "../plugins/fancybox/js/fancybox";
import cardSpoiler               from '../pug/mixins/card/card-spoiler'
import toast                             from '../pug/mixins/toast/toast'
import tooltips                          from "../pug/mixins/tooltips/tooltips";
import scrollbar                         from "../plugins/scrollbar/js/scrollbar";
import buttons                           from "../pug/mixins/buttons/buttons";
import {initValidator, validate}         from "../plugins/parsley.js/validator";
import {toggleValueClass}                from "./functions";
import calculator                        from "./calculator";
import mask                              from "../plugins/jquery-mask/mask";
import choices                           from "../plugins/choices.js/js/choices";
import formControls                        from '../pug/mixins/forms/form-controls'
import autocomplete, {resetAutocomplete} from "./autocomplete";
import {onJsonError, onJsonSuccess} from "./json-api";
import formControlNumber from "../pug/mixins/forms/text/form-control-number"
import formControlDate from "../pug/mixins/forms/text/form-control-date"
import file from "../pug/mixins/forms/file/file";
import tabs from "../pug/mixins/tabs/tabs";
import counterparty from './counterparty/index'
import counterpartyDetail from './counterparty/detail'
import counterpartyModal from './counterparty/counterpartyModal'
import tabContract from "./calc/tab-contract";
import estimate_index from './calc/index'

let $document = $(document);
$document.ready(function () {
    formControls($document, '.form-control', 'hover', 'focus', 'active')
    svg4everybody();
    toast($document)
    tooltips($document);
    fancybox($document);
    choices($document);
    scrollbar($document);
    buttons($document);
    initValidator($document, '[data-validated-form]', 'data-validated-')
    calculator($document)
    mask($document)
    autocomplete($document)
    cardSpoiler($document);
    formControlNumber($document)
    formControlDate($document)
    file($document)
    tabs($document)
    counterparty($document)
    counterpartyDetail($document)
    counterpartyModal($document)
    tabContract($document)
    estimate_index($document)
    // события ui
    const EVENT_FORM_CLEAN = "form.clean";
    const EVENT_DOM_UPDATE = "update";
    const EVENT_BINDING_REQUIRED = "js.bind";
    const FANCYBOX_CONTENT_LOADED = "afterLoad.fb";
    // атрибуты дом элементов
    const ATTR_DATA_UPDATE_URL = 'data-update-url';
    const ATTR_DATA_UPDATE_URL_SELECTOR = '[' + ATTR_DATA_UPDATE_URL + ']';
    const ATTR_DATA_ACTION_URL = 'data-action-url';
    const ATTR_DATA_ACTION_URL_SELECTOR = '[' + ATTR_DATA_ACTION_URL + ']';
    // отправка формы для простых форм
    const FORM_EVENT_SUBMIT = "submit";
    // отправка формы фильтра на сервер
    const FORM_EVENT_SUBMIT_FILTER = "submit:filter";
    // отправка формы когда есть действие на кнопке
    const FORM_EVENT_SUBMIT_ACTION = "submit:action";

    function findFormForElement($element) {
        if ($element.is('form')) return $element;
        if ($element.parents('form').length) {
            return $element.closest('form')
        }
        const formSelector = `#${$element.attr('form')}`;
        const $form = $(formSelector).length
                      ? $(formSelector)
                      : $element.closest('form');
        if (!$form.length) {
            console.error(`${formSelector} not found!`);
            return $();
        } else {
            return $form;
        }
    }

    function triggerSubmitFilter(e) {
        e.stopPropagation();
        e.preventDefault();
        findFormForElement($(e.currentTarget)).trigger(FORM_EVENT_SUBMIT_FILTER);
    }

    function triggerSubmitAction(e) {
        e.stopPropagation();
        e.preventDefault();
        const $element = $(e.currentTarget);
        let actionUrl = null;
        if ($element.is('a')) {
            actionUrl = $element.attr('href')
        } else if ($element.is('[data-action-url]')) {
            actionUrl = $element.attr('data-action-url')
        }
        findFormForElement($element).trigger(FORM_EVENT_SUBMIT_ACTION, actionUrl);
    }

    function resetFormFull(e) {
        const $form = $(e.currentTarget);
        setTimeout(function () {
            resetAutocomplete($form)
            $form.find('select').each(function (i, v) {
                v._plugin_choices.setChoiceByValue("");
                toggleValueClass($(v), $(v).closest('.form-control'))
            });
            $form.find('input, textarea').each(function (i, v) {
                const $input = $(v);
                $input.val("");
                toggleValueClass($input, $input.closest('.form-control'))
            });
            if ($form.find('.js-submit-on-change').length) {
                setTimeout(function () {
                    $form.trigger(FORM_EVENT_SUBMIT_FILTER)
                }, 1)
            }
        }, 1)
    }

    function onAjaxFormSubmit(e) {
        e.preventDefault();
        e.stopPropagation();
        const $form = $(e.currentTarget);
        if($form.is('.filter-form')){
            return;
        }
        validate($form)
            .done(function () {
                var params = {
                    url: $form.attr('action'),
                    type: "POST",
                    dataType: 'json',
                    beforeSend: function () {
                        $form
                            .addClass('loading')
                            .find('[type=submit]')
                            .attr('disabled', 'disabled');
                    },
                    complete: function () {
                        $form
                            .removeClass('loading')
                            .find('[type=submit]')
                            .removeAttr('disabled');
                    },
                    success: function (response, textStatus, jqXHR) {
                        onJsonSuccess($form, response, textStatus, jqXHR)
                    },
                    error: function (jqXHR, textStatus, errorThrown) {
                        onJsonError($form, jqXHR, textStatus, errorThrown)
                    }
                };
                let submitterData = null;
                if (e.originalEvent && e.originalEvent.submitter) {
                    // кнопка отправки формы может быть быть инпутом с каким то действием
                    const $submitter = $(e.originalEvent.submitter)
                    if ($submitter && $submitter.attr('name')) {
                        submitterData = {
                            name: $submitter.attr('name'),
                            value: $submitter.val()
                        }
                    }
                }else if($form.find('[data-clicked=true]').length){
                    // кнопка отправки формы может быть быть инпутом с каким то действием
                    const $submitter = $form.find('[data-clicked=true]')
                    if ($submitter && $submitter.attr('name')) {
                        submitterData = {
                            name: $submitter.attr('name'),
                            value: $submitter.val()
                        }
                    }
                }
                if (typeof FormData !== 'undefined') {
                    params.data = new FormData($form.get(0));
                    if (submitterData) {
                        params.data.append(submitterData.name, submitterData.value)
                    }
                    params.contentType = false;
                    params.processData = false;
                } else {
                    params.data = $form.serializeArray();
                    if (submitterData) {
                        params.data.push(submitterData)
                    }
                }
                $.ajax(params);
            })
    }

    function onAjaxFilterSubmit(e) {
        e.preventDefault();
        e.stopPropagation();
        const $form = $(e.currentTarget);
        const formData = $form
            .serializeArray()
            // пустые значения не нужно передавать, например пустой поиск
            .filter(e => e.value !== '')
            // страница сбрасывается, считаем что переходим на первую
            .filter(e => e.name !== 'page')
            // если постраничка по 30 элементов - ее тоже не надо передавать
            // TODO вынести этот параметр куда нибудь для конфигурации
            .filter(e => e.name !== 'limit' || e.value !== $form.attr('data-default-page-size'))
            .filter(e => e.name !== 'id[]');
        const contentContainerSelector = `.js-ajax-container[data-form-id=${$form.attr('id')}]`
        const paginationContainerSelector = `.js-ajax-pagination-container[data-form-id=${$form.attr('id')}]`
        // не склеиваем сразу в строку потому что знак '?' в конце урла может случиться при пустой formData
        const newUrl = [
            window.location.href.split('?')[0].split('#')[0],
            decodeURIComponent($.param(formData))
        ]
            .filter(s => s.length)
            .join('?');
        $.ajax({
            url: $form.attr('action'),
            data: formData,
            type: $form.attr('method')||'GET',
            crossDomain: false,
            dataType: 'html',
            beforeSend: function () {
                $(contentContainerSelector).addClass('loader');
            },
            complete: function () {
                $(contentContainerSelector).removeClass('loader');
            },
            success: function (response, textStatus, jqXHR) {
                onJsonSuccess($form, null, textStatus, jqXHR);
                const content = $(response)
                    .find('.js-ajax-container')
                    .addBack('.js-ajax-container')
                    .html();
                const pagination = $(response)
                    .find('.js-ajax-pagination-container')
                    .addBack('.js-ajax-pagination-container')
                    .html();
                history.replaceState('', '', newUrl);
                $(contentContainerSelector)
                    .empty()
                    .append(content)
                    .trigger('js.bind');
                $(paginationContainerSelector)
                    .empty()
                    .append(pagination)
                    .trigger('js.bind');
            },
            error: function (jqXHR, textStatus, errorThrown) {
                onJsonError($form, jqXHR, textStatus, errorThrown)
            }
        });
    }

    function onAjaxActionSubmit(e, actionURL) {
        e.preventDefault();
        e.stopPropagation();
        const $form = $(e.currentTarget);
        const formData = $form
            .serializeArray()
            // пустые значения не нужно передавать, например пустой поиск
            .filter(e => e.value !== '')
            // страница сбрасывается, считаем что переходим на первую
            .filter(e => e.name !== 'page')
            // если постраничка по 30 элементов - ее тоже не надо передавать
            // TODO вынести этот параметр куда нибудь для конфигурации
            .filter(e => e.name !== 'limit' || e.value !== $form.attr('data-default-page-size'));
        $.ajax({
            url: !!actionURL
                 ? actionURL
                 : $form.attr('action'),
            type: 'POST',
            data: formData,
            dataType: 'html',
            beforeSend: function () {
                $form.addClass('loading')
            },
            complete: function () {
                $form.removeClass('loading')
            },
            success: function (response, textStatus, jqXHR) {
                onJsonSuccess($form, response, textStatus, jqXHR)
            },
            error: function (jqXHR, textStatus, errorThrown) {
                onJsonError($form, jqXHR, textStatus, errorThrown)
            }
        })
    }


    $(document)
        .on('change', '.pagination [name=limit]', triggerSubmitFilter)
        .on('change autocomplete-change', '.js-submit-on-change', triggerSubmitFilter)
        .on('change', '.js-submit-on-change-action', triggerSubmitAction)
        .on('reset', 'form', resetFormFull)
        .on(FORM_EVENT_SUBMIT, 'form', onAjaxFormSubmit)
        .on(FORM_EVENT_SUBMIT_FILTER, 'form', onAjaxFilterSubmit)
        .on(FORM_EVENT_SUBMIT_ACTION, 'form', onAjaxActionSubmit)
        .on(EVENT_DOM_UPDATE, ATTR_DATA_UPDATE_URL_SELECTOR, function (e) {
            e.preventDefault();
            e.stopPropagation();
            const $element = $(e.currentTarget);
            let params = {
                url: $element.attr(ATTR_DATA_UPDATE_URL),
                type: "GET",
                beforeSend: function () {
                    $element.addClass('loading')
                },
                complete: function () {
                    $element.removeClass('loading')
                },
                success: function (response) {
                    let $response = $(response);
                    $element.replaceWith($response);
                    $response.trigger(EVENT_BINDING_REQUIRED);
                }
            };
            if ($element.is('form') || $element.parents('form').length) {
                params.data = findFormForElement($element).serializeArray();
                params.type = "POST";
            }
            $.ajax(params);
        })
        // когда fancybox подгрузит контент - биндим на него динамику
        .on(FANCYBOX_CONTENT_LOADED, function (event, fancybox, current) {
            $(current.$content).trigger(EVENT_BINDING_REQUIRED)
        })

    var App = {
        numberWithCommas: function (x) {
            return x.toString().replace(/\B(?=(\d{3})+(?!\d))/g, " ");
        },
        translateDate: function () {
            return {
                days: [
                    'Понедельник',
                    'Вторник',
                    'Среда',
                    'Четверг',
                    'Пятница',
                    'Суббота',
                    'Воскресенье'
                ],
                daysShort: [
                    'Вс',
                    'Пн',
                    'Вт',
                    'Ср',
                    'Чт',
                    'Пт',
                    'Сб'
                ],
                daysMin: [
                    'Вс',
                    'Пн',
                    'Вт',
                    'Ср',
                    'Чт',
                    'Пт',
                    'Сб'
                ],
                months: [
                    'Январь',
                    'Февраль',
                    'Март',
                    'Аперль',
                    'Май',
                    'Июнь',
                    'Июль',
                    'Август',
                    'Сентябрь',
                    'Октябрь',
                    'Ноябрь',
                    'Декабрь'
                ],
                monthsShort: [
                    'Янв',
                    'Фев',
                    'Март',
                    'Апр',
                    'Май',
                    'Июнь',
                    'Июль',
                    'Авг',
                    'Сен',
                    'Окт',
                    'Ноя',
                    'Дек'
                ],
                weekStart: 1,
                startView: 0,
            }
        },
        eventListener: function () {
            $('.table__body').on('click', '.item', function () {
                $(this).toggleClass('active');
            });
            $('.table__body').on('click', '.detail-info', function (e) {
                e.stopPropagation();
            });
            $('.table__body').on('click', '.edit', function (e) {
                e.stopPropagation();
            });
            $('input[data-price="true"]').change(function () {
                var formatValue = App.numberWithCommas($(this).val());
                $(this).val(formatValue);
            })
            $('body').on('click', function (event) {
                if (!$(event.target).closest('.project-info').length === 0) {
                    $('.table__body .item').removeClass('active');
                }
                // if ($(event.target).closest('.js-status-toggler').length === 0) {
                //     $('.status-popup').removeClass('open');
                // }
                if ($(event.target).closest('.js-export-btn').length === 0) {
                    $('.export-popup').removeClass('open');
                }
                if ($(event.target).closest('.form').length === 0) {
                    $('.search-popup').hide();
                }
            });
            $('.status-view').hover(function () {
                $(this).children('.status-view__descriptions').addClass('active');
            }, function () {
                $(this).children('.status-view__descriptions').removeClass('active');
            })
            $('.js-payouts-list__head').on('click', function () {
                $(this).parent().toggleClass('close');
                $(this).next('.payouts-list__body').toggle();
            })
            //делаем копию в контрагентах, требует доработка(не работает селект)
            $('.js-copy-prev-block').on('click', function () {
                $(this).before($(this).prev().clone());
            });
            $('.js-status-toggler').on('click', function () {
                $(this).next('.status-popup').toggleClass('open');
            });
            $document.mouseup(function (e) { // событие клика по веб-документу
                var div = $(".js-status-select"); // тут указываем ID элемента
                if (!div.is(e.target) // если клик был не по нашему блоку
                    && div.has(e.target).length === 0) { // и не по его дочерним элементам
                    div.find('.status-popup').removeClass('open');// скрываем его
                }
            });
            $('body').on('click', '.js-select-choice', function () {
                var selectText = $(this).html();
                var selectStatus = $(this).data('status');
                var currentStatus = $(this).closest('.js-status-select').find('.js-select-status')
                var curentStatusText = $(this).closest('.js-status-select').find('.js-status-text');
                currentStatus.removeClass('open in-process success').addClass(selectStatus);
                curentStatusText.html(selectText);
            });
            $('.js-export-btn').on('click', function () {
                $(this).next('.export-popup').addClass('open');
            });
            $('.js-leasing-getter').change(function () {
                $('.pagination').hide();
                $('.calculation_list .table__body').html('<div class="empty-request"><p>По вашему запросу не найдено. </p></div>');
            });
            //  // dom manipulation with popup
            $('.js-edit-task-btn').on('click', function () {
                $('.edit-task-form').addClass('open');
            });
            $('.js-delete-task-btn').on('click', function () {
                $('.delete-task-form').addClass('open');
            });
            $('.js-archive-task-btn').on('click', function () {
                $('.archive-task-form').addClass('open');
            });
            $('.js-add-new-project').on('click', function () {
                $('.make-project-form.popup_form_bg').addClass('open');
            });
            // кастомная загрузка и очистка документа
            $('.js-field-file input[type="file"]').on("change", function () {
                $(this).closest('.col').hide();
                $('.downloaded-doc').addClass('show');
                $('.downloaded-doc .title').html(this.files[0].name);
            });
            $('.js-delete-doc').on('click', function () {
                $(this).closest('.downloaded-doc').removeClass('show');
                $(this).closest('.col').prev().show();
                ('.field-file input[type="file"]').val("");
            });
            $('.js-download-doc').on('click', function () {
                $('.choose_btn').click();
            });
            $('input[name="selected-agent"]').on('input', function () {
                if (val.trim().length > 2) {
                    // тут будет функция отправки ajax для вывода агентов в расчетах
                    $(this).next('.js-agent-search-popup').show();
                }
            });
            $('body').on('click', '.js-agent-search-popup li', function () {
                let text = $(this).text();
                $('input[name="selected-agent"]').val(text);
            })
            $('input[name="counterparty-name"]').on('input', function () {
                $('input[name="counterparty-name"]').removeClass('find');
                let val = $(this).val();
                if (val.trim().length > 2) {
                    // тут будет функция отправки ajax для вывода спикска в контрагентов и показываеи список если есть найденные
                    // $(this).siblings('.js-counterparty-name-popup').show();
                }
            });
            $('body').on('click', '.js-counterparty-name-popup li', function () {
                $('.not-find-counterparty').removeClass('show');
                $('input[name="counterparty-name"]').addClass('find'); // добавляем к инпуту класс, метка, что агент взят из списка
                let text = $(this).text();
                $('input[name="counterparty-name"]').val(text);
                $('.assignment-fields').find('.select').removeClass('disabled');
            });
            $('input[name="counterparty-name"]').on('change', function () { //после изменения поля ввода проверяем найден ли контрагент или создаем новый
                if ($(this).hasClass('find')) {
                    $('.not-find-counterparty').removeClass('show');
                } else {
                    $(this).addClass('error');
                    $('.error-msg').show();
                    $('.not-find-counterparty').addClass('show'); // показываем popup для создания нового контрагента
                }
                if (!$('.assignment-fields').find('.select').hasClass('disabled')) {
                    $('.assignment-fields').find('.js-assign-counterparty').removeClass('disabled');
                }
            });
            //кнопка создания контрагента
            $('.js-make-counterparty').on('click', function () {
                $('input[name="counterparty-name"]').addClass('success');
                $('.not-find-counterparty').removeClass('show');
                $('.error-msg').hide();
                $('.assignment-fields').find('.select').removeClass('disabled');
            });
            //выбираем проект и показываем кнопку
            $('.assignment-fields').find('.select').change(function () {
                $('.assignment-fields').find('.js-assign-counterparty').removeClass('disabled');
            });
            $('.js-assign-counterparty').on('click', function () {
                $('.assignment-fields').find('.js-assign-counterparty').addClass('disabled'); // блочим кнопку
                // тут будет функция отправки данных на сервер
                $('.calculation-assign-mark').show(); //если ок
            });
        },
        initPlugins: function () {
            setTimeout(function () {
                $('body').addClass('on-start');
            }, 50);
            setTimeout(function () {
                $('body').addClass('document-loaded');
            }, 1200);
            feather.replace({
                'stroke': '#ffffff',
                'stroke-width': 1.5
            });
        },
        init: function () {
            this.eventListener();
            return this;
        }
    };
    App.initPlugins();
    App.init();
    window.dll = App;

    $document
        .on('ajaxComplete', function (event, jqXHR, options) {
            // сервер может попросить залогиниться - просто обновим страницу
            if (302 === jqXHR.status || 403 === jqXHR.status) {
                window.location.reload()
            }
        })
        .on('change input', 'input, textarea', function () {
            const $this = $(this);
            toggleValueClass($this, $this.closest('.form-control'));
        });
    setTimeout(function () {
        $document.trigger('js.bind')
    }, 1)
});
