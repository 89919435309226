export function toggleValueClass($input, $wrapper) {
    if($input.is('.no-label-interaction')) return;
    // при изменении инпутов надо чтобы на обертке переключался класс has-value, он влияет на отображение лейбла
    $wrapper.toggleClass('has-value', ("" + $input.val()) !== "")
}
export function range(from, to) {
    from = Math.min(from, to)
    to = Math.max(from, to)
    return [...Array(to - from + 1).keys()].map(i => i + from);
}
export function highlightString($text, $highlights) {
    var $chars = $text.split("")
    var $lowcase = $text.toLowerCase();
    var $charMap   = Array($chars.length);
    if(typeof  $highlights != typeof []){
        $highlights = [$highlights];
    }
    $highlights.forEach(function ($term) {
        var $lastPos = 0;
        var $termLength = $term.length;
        var $termLower = $term.toLowerCase();
        while ( ( $lastPos = $lowcase.indexOf($termLower, $lastPos) )  !== -1 ) {
            range($lastPos, $lastPos + $termLength - 1).forEach(function ($position) {
                $charMap[$position] = true;
            })
            $lastPos = $lastPos + $termLength;
        }
    })

    var $result       = "";
    var $rangeStarted = false;
    $chars.forEach(function ($char, $i) {
        if ( $charMap[ $i ] && ! $rangeStarted ) {
            $result       += "<b>";
            $rangeStarted = true;
        }
        if ( ! $charMap[ $i ] && $rangeStarted ) {
            $result       += "</b>";
            $rangeStarted = false;
        }
        $result += $char;
    })
    if ( $rangeStarted ) {
        $result += "</b>";
    }
    return $result.trim();
}
export function isNumber(value) {
    return typeof value === 'number' && !isNaN(value);
}
