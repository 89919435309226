<template>
  <div class="tabs-outer">
    <ul class="tabs">
      <li class="tab active" data-tag-target="tab1">Общие сведения</li>
    </ul>
    <div class="tabs-content">
      <div class="tabs-content-wrap active" data-tab="tab1">
        <SimpleForm name="counterparty" ref="form">
          <BaseInfo
              :value="basic"
              :onEntrySelected="onEntrySelected"
              :fetchByInn=api.fetchByInn
              :fetchByName=api.fetchByName
              @update:value="basicChange"
          />
          <Card :collapsible=true :open-at-start=true title="Контакты">
            <TransitionListLeave>
              <Contact
                  v-for="contact in contacts"
                  :value="contact"
                  :key="contact.guid"
                  @update:value="onEntityChange('contact', contact.guid, $event)"
                  :onDelete="()=>deleteEntity('contact', contact.guid)"
              />
            </TransitionListLeave>
            <div class="contact-footer">
              <a @click="addEntity('contact')" class="h4 add-entity">+ Добавить</a>
            </div>
            <br>
            <div class="h4">Юридический адрес *</div>
            <br>
            <Address
                :value=legalAddress
                :addressSummary="addressSummary"
                @update:value="onLegalAddressChange"
                :autocomplete-url="api.fetchAddress"
            />
            <div class="h4">Фактический адрес *</div>
            <br>
            <Fieldset>
              <CheckboxControl
                  :value=realAddressMatchLegal
                  checkboxLabel="Совпадает с юридическим адресом"
                  @update:value="toggleRealAddress"
              />
            </Fieldset>
            <TransitionGroupExpandCollapse>
              <Address
                  :value=realAddress
                  :addressSummary="addressSummary"
                  @update:value="onRealAddressChange"
                  v-if="!realAddressMatchLegal"
                  :autocomplete-url="api.fetchAddress"
              />
              <p v-if="realAddressMatchLegal">{{ realAddressPresentation }}</p>
            </TransitionGroupExpandCollapse>
            <div class="h4">Почтовый адрес *</div>
            <br>
            <Fieldset>
              <CheckboxControl
                  :value=postAddressMatchReal
                  checkboxLabel="Совпадает с фактическим адресом"
                  @update:value="togglePostAddress"
              />
            </Fieldset>
            <TransitionGroupExpandCollapse>
              <Address
                  :value=postAddress
                  :addressSummary="addressSummary"
                  @update:value="onPostAddressChange"
                  v-if="!postAddressMatchReal"
                  :autocomplete-url="api.fetchAddress"
              />
              <p v-if="postAddressMatchReal">{{ postAddressPresentation }}</p>
            </TransitionGroupExpandCollapse>
            <Fieldset>
              <TextControl :value="phone" class="phone" @update:value="onPhoneChanged" label="Контактный телефон"
                           mask="phone" key=".phone"/>
              <TextControl :value="email" class="email" @update:value="onEmailChanged" label="Электронная почта"
                           mask="email" key=".email"/>
            </Fieldset>
          </Card>
          <Card :collapsible=true :open-at-start=true title="Подписант">
            <TransitionListLeave>
              <Signer
                  v-for="(signer, i) in signers"
                  :key="signer.guid"
                  :title=signerTitle(i)
                  :value="signer"
                  @update:value="onEntityChange('signer', signer.guid, $event)"
                  :options="selectOptions.basis"
                  :onDelete="()=>deleteEntity('signer', signer.guid)"
              />
            </TransitionListLeave>
            <p>
              <a @click="addEntity('signer')" class="h4 add-entity">+ Добавить</a>
            </p>
          </Card>
          <Card :collapsible=true :open-at-start=true title="Реквизиты" class="requisite-card">
            <TransitionListLeave>
              <Requisite
                  v-for="req in requisites"
                  :key="req.guid"
                  :value="req"
                  @update:value="onEntityChange('requisite', req.guid, $event)"
                  :onDelete="()=>deleteEntity('requisite', req.guid)"
                  :autocomplete-url="api.fetchBank"
              />
            </TransitionListLeave>
            <br>
            <div>
              <a @click="addEntity('requisite')" class="h4 add-entity">+ Добавить</a>
            </div>
          </Card>
          <Card title="Дополнительные данные" :open-at-start="true" :collapsible="true">
            <Fieldset>
              <TextControl
                  :value="other.kpp"
                  :readonly="true"
                  class="kpp"
                  @update:value="otherChanged('kpp', $event)"
                  label="КПП"
                  key=".kpp"
              />
              <TextControl
                  :value="other.okpo"
                  :readonly="true"
                  class="okpo"
                  @update:value="otherChanged('okpo', $event)"
                  label="ОКПО"
                  key=".okpo"
              />
              <DateControl
                  :value="other.sellerDate"
                  :readonly="!basic.roles.dealer"
                  @update:value="otherChanged('sellerDate', $event)"
                  label="Дата общих условий с продавцом"
                  key=".sellerDate"
              />
            </Fieldset>
          </Card>
          <p>* Обязательные поля</p>
          <BottomPanel v-if="!inline">
            <FlexRow>
              <button class="button button-primary" @click="save" v-bind:disabled="!canSubmit">Сохранить</button>
            </FlexRow>
            <FlexRow>
              <FancyboxLink :href="api.bugReport" title="Сообщить об ошибке">Сообщить об
                ошибке
              </FancyboxLink>
            </FlexRow>
          </BottomPanel>
          <div v-if="inline" class="panel-content">
            <FlexRow>
              <button class="button button-primary" @click="save" v-bind:disabled="!canSubmit">Сохранить</button>
            </FlexRow>
            <FlexRow>
              <FancyboxLink :href="api.bugReport" title="Сообщить об ошибке">Сообщить об
                ошибке
              </FancyboxLink>
            </FlexRow>
          </div>
        </SimpleForm>
      </div>
    </div>
  </div>
</template>
<style scoped>
.phone, .email {
  width: 320px;
}

.add-entity {
  color: rgba(0, 153, 255, 1);
}

.kpp, .okpo {
  width: 120px;
}
</style>
<style>
.entity-head {
  display: flex;
  flex-direction: row;
  align-items: baseline;
  justify-content: space-between;
  margin-bottom: 21px;
}

.entity-head a {
  color: rgba(0, 153, 255, 1)
}

.requisite-card .card-content {
  padding-top: 0;
}
</style>
<script type="application/ecmascript">
import Breadcrumbs from "../components/Breadcrumbs";
import CheckboxSet from "../components/form/CheckboxSet";
import AutocompleteText from "../components/form/AutocompleteText";
import SelectControl from "../components/form/SelectControl";
import TextControl from "../components/form/TextControl";
import Card from "../components/Card";
import Contact from "./Contact";
import SearchableSelect from "../components/form/SearchableSelect";
import Address from "./Address";
import CheckboxControl from "../components/form/CheckboxControl";
import Fieldset from "../components/form/Fieldset";
import BaseInfo from "./BaseInfo";
import {uuid, validateObject} from "../utils";
import TransitionGroupExpandCollapse from "../components/transitions/TransitionGroupExpandCollapse";
import TransitionListLeave from "../components/transitions/TransitionListLeave";
import Subscriber from "./Signer";
import Signer from "./Signer";
import Requisite from "./Requisite";
import DateControl from "../components/form/DateControl";
import BottomPanel from "../components/form/BottomPanel";
import FlexRow from "../components/layout/FlexRow";
import SimpleForm from "../components/form/SimpleForm";
import FancyboxLink from "../components/FancyboxLink";
import SvgLink from "../components/SvgLink";
import {mapMutations} from "vuex";
import api from "../api";

export default {
  props: {
    breadcrumbs: {type: Object, required: true},
    initial: {type: Object, required: true},
    selectOptions: {type: Object, required: true},
    inline:{type:Boolean, required:false, default:false},
    api: {
      type: Object, required: true, validator: function (value) {
        const missing = validateObject(value, [
          'fetchByInn',
          'fetchByName',
          'fetchAddress',
          'fetchBank',
          'delete',
          'save',
          'bugReport'
        ])
        if (missing.length) {
          console.error(`missing keys for object api: [${missing.join(',')}]`)
        }
        return missing.length === 0
      }
    }
  },
  data() {
    return {
      ...this.initial,
      isLoading: false
    }
  },
  computed: {
    basic() {
      return {
        roles: this.roles,
        inn: this.inn,
        shortName: this.shortName,
        fullName: this.fullName
      }
    },
    realAddressPresentation() {
      return this.realAddress.presentation
    },
    postAddressPresentation() {
      return this.postAddress.presentation
    },
    canSubmit(){
      const anyRoleChecked = this.roles.dealer||this.roles.lessee||this.roles.lessor||this.roles.insurer
      return !this.isLoading && anyRoleChecked
    }
  },
  watch: {
    legalAddress: {
      handler: function (n) {
        if (this.realAddressMatchLegal) {
          this.realAddress = n
        }
      }
    },
    realAddress: {
      handler: function (n) {
        if (this.postAddressMatchReal) {
          this.postAddress = n
        }
      }
    }
  },
  methods: {
    ...mapMutations([
      'addError', 'dropAllErrors'
    ]),
    joinStrings(arr, separator) {
      return arr
          .filter(it => typeof it !== "undefined")
          .filter(it => null !== it)
          .filter(s => s.trim().length > 0)
          .join(separator)
    },
    addressSummary(address) {
      return this.joinStrings([
        this.joinStrings([address.zip, address.country], ' '),
        address.region,
        address.city,
        address.street,
        this.joinStrings([address.houseType, address.house], ' '),
        this.joinStrings([address.blockType, address.block], ' '),
        this.joinStrings([address.flatType, address.flat], ' '),
      ], ', ')
    },
    basicChange(basic) {
      this.roles = basic.roles
      this.inn = basic.inn
      this.shortName = basic.shortName
      this.fullName = basic.fullName
    },
    onEntrySelected(entry) {
      this.inn = entry.inn.toString()
      this.shortName = entry.shortName.toString()
      this.fullName = entry.fullName.toString()
      this.legalAddress = {
        ...entry.address,
        presentation: entry.presentation,
        comment: this.legalAddress.comment
      }
      this.otherChanged('kpp', entry.kpp)
      this.otherChanged('okpo', entry.okpo)
      if (entry.signer) {
        this.signers = [entry.signer]
      }
    },
    onLegalAddressChange(value) {
      this.legalAddress = value
    },
    onPostAddressChange(value) {
      this.postAddress = value
    },
    onRealAddressChange(value) {
      this.realAddress = value
    },
    toggleRealAddress(boolean) {
      this.realAddressMatchLegal = boolean
    },
    togglePostAddress(boolean) {
      this.postAddressMatchReal = boolean
    },
    signerTitle(index) {
      return `Подписант ${++index}`
    },

    onEntityChange(entityType, guid, values) {
      switch (entityType) {
        case 'contact':
          this.contacts = this.contacts.map(it => {
            return it.guid === guid ? values : it
          })
          break;
        case 'signer':
          this.signers = this.signers.map(it => {
            return it.guid === guid ? values : it
          })
          break;
        case 'requisite':
          this.requisites = this.requisites.map(it => {
            return it.guid === guid
                ? values
                : {...it, main: values.main ? false : it.main}
          })
          break;
      }
    },
    addEntity(entityType) {
      switch (entityType) {
        case 'contact':
          this.contacts.push({
            guid: uuid(),
            lastName: "",
            initials: "",
            job: "",
            phone: "",
            email: ""
          })
          break;
        case 'signer':
          this.signers.push({
            guid: uuid(),
            lastName: "",
            initials: "",
            job: "",
            level: '',
            basis: '',
            number: '',
            date: null
          })
          break;
        case 'requisite':
          this.requisites.push({
            guid: uuid(),
            bik: '',
            bank: '',
            corr: '',
            account: '',
            main: this.requisites.length == 0
          })
          break;
      }
    },
    deleteEntity(entityType, guid) {
      const del = it => guid !== it.guid
      switch (entityType) {
        case 'contact':
          this.contacts = this.contacts.filter(del)
          break;
        case 'signer':
          this.signers = this.signers.filter(del)
          break;
        case 'requisite':
          this.requisites = this.requisites.filter(del)
          break;
      }
    },
    otherChanged(prop, value) {
      switch (prop) {
        case 'kpp':
          this.other.kpp = value;
          break;
        case 'okpo':
          this.other.okpo = value;
          break;
        case 'sellerDate':
          this.other.sellerDate = value;
          break;
      }
    },
    /**
     * @param payload
     * @return {Promise}
     */
    validate(payload) {
      this.dropAllErrors()
      this.scrollToError()
      return Promise.resolve()
    },
    scrollToError(){
      const els = this.$refs.form.$el
      if(els){
        setTimeout(()=>{
          const errorElement = els.querySelector('.form-control.error')
          if(errorElement && errorElement instanceof Element){
            errorElement.scrollIntoView({behavior: "smooth", block: "center", inline: "nearest"});
          }
        }, 1)
      }
    },
    save() {
      const payload = JSON.parse(JSON.stringify({
        ...this.basic,
        contacts: this.contacts,
        legalAddress: this.legalAddress,
        realAddress: this.realAddress,
        postAddress: this.postAddress,
        phone: this.phone,
        email: this.email,
        signers: this.signers,
        requisites: this.requisites,
        ...this.other
      }));

      this
          .validate(payload)
          .then(() => {
                this.isLoading = true;
                return api.post(this.api.save, payload)
              }
          )
          .then(({data}) => this.onResult(data))
          .catch(e=>{
            if(e.response&&e.response.data){
              this.onResult(e.response.data)
            }
          })
          .finally(() => {
            this.isLoading = false
          })
    },
    onPhoneChanged(value) {
      this.phone = value
    },
    onEmailChanged(value) {
      this.email = value
    },
    onResult(json){
      if (json.hasOwnProperty('errors')) {
        const errors = json.errors
        if (errors instanceof Array) {
          errors.forEach(this.addError)
        }else if(typeof errors === 'object'){
          if(errors.hasOwnProperty('_')){
            $('<div />', {
              html: errors._
            }).showAsToast(false)
          }
        }
        this.scrollToError()
      }
    }
  },
  components: {
    SvgLink,
    FancyboxLink,
    SimpleForm,
    FlexRow,
    BottomPanel,
    DateControl,
    Requisite,
    Signer,
    Subscriber,
    TransitionListLeave,
    TransitionGroupExpandCollapse,
    BaseInfo,
    Fieldset,
    CheckboxControl,
    Address,
    SearchableSelect, Contact, Card, TextControl, SelectControl, AutocompleteText, CheckboxSet, Breadcrumbs
  },
}
</script>
