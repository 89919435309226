<template>
  <Modal :title="title">
    <ModalForm
        :loading="loading"
        :action="action"
        :get-form-data="getFormData"
        :on-response="onResponse"
        :validate="validate"
        @update:loading="onLoadingChange"
        ref="form"
        class="modal-form"
    >
      <AutocompleteText :url="autocompleteUrl"
                        :lens="e=>e.inn"
                        :handler="onEntrySelected"
                        v-bind:value="form.inn"
                        @update:value="onPropUpdate('inn', $event)"
                        mask="inn"
                        key=".inn"
                        @paste:value="onPasteInn($event)"
                        label="ИНН"/>
      <AutocompleteText :url="autocompleteUrl"
                        :lens="e=>e.fullName"
                        :handler="onEntrySelected"
                        v-bind:value="form.fullName"
                        @update:value="onPropUpdate('fullName', $event)"
                        key=".fullName"
                        label="Полное наименование лизингополучателя"
      />
      <TextControl
          v-bind:value="form.docLink"
          @update:value="onPropUpdate('docLink', $event)"
          key=".docLink"
          label="Ссылка на документы"
      />
      <TextareaControl
          v-bind:value="form.extraInfo"
          @update:value="onPropUpdate('extraInfo', $event)"
          key=".extraInfo"
          label="Дополнительно"
      />
      <div class="form-actions">
        <button class="button button-primary"
                v-bind:disabled="loading||!valid"
                @click="onClick"
        >{{buttonText}}</button>
      </div>
    </ModalForm>
  </Modal>
</template>
<script>
import Modal from "../components/Modal";
import SimpleForm from "../components/form/SimpleForm";
import TextControl from "../components/form/TextControl";
import AutocompleteText from "../components/form/AutocompleteText";
import TextareaControl from "../components/form/TextareaControl";
import ModalForm from "../components/form/ModalForm";
import axios from "axios";
import {validateObject} from "../utils";

export default {
  props: {
    title: {type: String, required: true},
    action: {type: String, required: true},
    autocompleteUrl: {type: String, required: true},
    buttonText:{type:String, required:true},
    initial:{
      type:Object,
      required:true,
      validator: function (value) {
        const missing = validateObject(value, [
          'inn',
          'fullName',
          'docLink',
          'extraInfo',
        ])
        if (missing.length) {
          console.error(`missing keys: [${missing.join(',')}]`)
        }
        return missing.length === 0
      }
    }
  },
  data() {
    return {
      loading: false,
      form: JSON.parse(JSON.stringify(this.initial))
    }
  },
  computed: {
    valid() {
      return this.form.inn.length == 10 && this.form.fullName.length > 0 && this.form.docLink.length > 0
    },
  },
  methods: {
    validate() {
      return this.valid ? Promise.resolve() : Promise.reject()
    },
    onEntrySelected(entry) {
      const form = this.getFormData()
      form['inn'] = entry.inn.toString()
      form['fullName'] = entry.fullName.toString()
      this.form = form
    },
    onPropUpdate(param, value) {
      const form = this.getFormData()
      form[param] = value
      this.form = form
    },
    onPasteInn(value) {
      this.onPropUpdate('inn', value)

      if (value.length != 10) return

      const params = {
        query: value
      }
      const headers = {
        'Content-Type': 'application/json',
        'X-Requested-With': 'XMLHttpRequest'
      }
      axios
          .get(this.autocompleteUrl, {params: params, headers: headers})
          .then(result => {
            const data = result.data.items
            if (data.length > 0) {
              this.onEntrySelected(data[0])
            }
          })
          .catch(e => console.error(e))
    },
    onResponse() {
    },
    getFormData() {
      return this.form
    },
    onClick(e) {
      e.preventDefault();
      e.stopPropagation();
      this.$refs.form.submit()
    },
    onLoadingChange(value) {
      this.loading = value
    }
  },
  components: {ModalForm, TextareaControl, AutocompleteText, TextControl, SimpleForm, Modal}
}
</script>