import { render } from "./modal-new-contract.vue?vue&type=template&id=564de952"
import script from "./modal-new-contract.vue?vue&type=script&lang=js"
export * from "./modal-new-contract.vue?vue&type=script&lang=js"
script.render = render
/* hot reload */
if (module.hot) {
  script.__hmrId = "564de952"
  const api = __VUE_HMR_RUNTIME__
  module.hot.accept()
  if (!api.createRecord('564de952', script)) {
    api.reload('564de952', script)
  }
  
  module.hot.accept("./modal-new-contract.vue?vue&type=template&id=564de952", () => {
    api.rerender('564de952', render)
  })

}

script.__file = "src/js/counterparty/modal-new-contract.vue"

export default script