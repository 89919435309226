import { render } from "./TextareaControl.vue?vue&type=template&id=56e07204&scoped=true"
import script from "./TextareaControl.vue?vue&type=script&lang=js"
export * from "./TextareaControl.vue?vue&type=script&lang=js"

import "./TextareaControl.vue?vue&type=style&index=0&id=56e07204&scoped=true&lang=css"
script.render = render
script.__scopeId = "data-v-56e07204"
/* hot reload */
if (module.hot) {
  script.__hmrId = "56e07204"
  const api = __VUE_HMR_RUNTIME__
  module.hot.accept()
  if (!api.createRecord('56e07204', script)) {
    api.reload('56e07204', script)
  }
  
  module.hot.accept("./TextareaControl.vue?vue&type=template&id=56e07204&scoped=true", () => {
    api.rerender('56e07204', render)
  })

}

script.__file = "src/js/components/form/TextareaControl.vue"

export default script