<template>
	<a v-bind:class="['button button-outline2', $attrs.class||'']"
	   v-bind:data-fancybox=href
	   v-bind:data-src=href
	   v-bind:href=href
	   data-type="ajax"
	>
		<slot/>
	</a>
</template>
<script>
  export default {
    props: {
      href: String
    }
  }
</script>