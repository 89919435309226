import { render } from "./SvgIcon.vue?vue&type=template&id=e3c9833e"
import script from "./SvgIcon.vue?vue&type=script&lang=js"
export * from "./SvgIcon.vue?vue&type=script&lang=js"
script.render = render
/* hot reload */
if (module.hot) {
  script.__hmrId = "e3c9833e"
  const api = __VUE_HMR_RUNTIME__
  module.hot.accept()
  if (!api.createRecord('e3c9833e', script)) {
    api.reload('e3c9833e', script)
  }
  
  module.hot.accept("./SvgIcon.vue?vue&type=template&id=e3c9833e", () => {
    api.rerender('e3c9833e', render)
  })

}

script.__file = "src/js/components/SvgIcon.vue"

export default script