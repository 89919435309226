<template>
	<transition-group appear @leave="transitionCollapse" @enter="transitionExpand">
		<slot/>
	</transition-group>
</template>
<style scoped>
	.v-leave-active {
		overflow: hidden;
	}
</style>
<script>
	import gsap from "gsap/all";
	import {ANIMATION_DURATION} from "../../const";

	export default {
		methods: {
			transitionExpand(el, done) {
				gsap.fromTo(el,
					{
						opacity: 0,
						translateY: -100,
					},
					{
						opacity: 1,
						translateY: 0,
						duration: ANIMATION_DURATION,
						onComplete: done
					}
				)
			},
			transitionCollapse(el, done) {
				gsap.fromTo(el,
					{
						opacity: 1,
						marginBottom: 0,
						marginTop: 0
					},
					{
						opacity: 0,
						height: 0,
						marginBottom: 0,
						marginTop: 0,
						duration: ANIMATION_DURATION,
						onComplete: done
					})
			}
		}
	}
</script>