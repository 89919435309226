<template>
	<form v-bind:class="[{'loading':loading}]"
	      :action="action"
	      autocomplete="off"
        ref="form"
	      :method="$attrs.method || 'post'"
	      novalidate=""
        @submit="(e)=>e.stopPropagation()"
  >
		<slot/>
	</form>
</template>
<script>
  import {mapMutations} from "vuex";
  import api from "../../api";

  export default {
    methods:{
      ...mapMutations([
          'addError','dropError','dropAllErrors',
      ]),
      scrollToError(){
        const els = this.$refs.form.$el
        if(els){
          setTimeout(()=>{
            const errorElement = els.querySelector('.form-control.error')
            if(errorElement && errorElement instanceof Element){
              errorElement.scrollIntoView({behavior: "smooth", block: "center", inline: "nearest"});
            }
          }, 1)
        }
      },
      onError(response){
        const json = !!response.json ? response.json : {};

        if (json.hasOwnProperty('errors')) {
          const errors = json.errors
          if (errors instanceof Array) {
            errors.forEach(this.addError)
          }else if(typeof errors === 'object'){
            if(errors.hasOwnProperty('_')){
              $('<div />', {
                html: errors._
              }).showAsToast(false)
            }
          }
          this.scrollToError()
        }

        if(!!response.headers['content-type'] && response.headers['content-type'].indexOf('text/html')===0){
         $.fancybox.open(response.data, {width:500, height:500, baseClass:'critical-error-omg'})
        }
      },
      onSuccess(response){

      },
      submit(){
        const payload = JSON.parse(JSON.stringify(this.getFormData()))

        this
          .validate()
          .then(()=>{
            this.dropAllErrors()
            this.$emit('update:loading', true)
            return api.request({
              url: this.action,
              method:this.$attrs.method || 'post',
              data:payload
            })
          })
          .then((response)=>{
            this.onSuccess(response)
            this.onResponse(response)
          })
          .catch(e=>{
            console.error(e)
            if(e.response){
              this.onResponse(e.response)
              this.onError(e.response)
            }
          })
          .finally(() => {
            this.$emit('update:loading', false)
          })
      }
    },
    emits:['update:loading'],
    props: {
      action: String,
      loading: Boolean,
      validate:{type:Function, required:true},
      getFormData:{type:Function, required:true},
      onResponse:{type:Function, required:true}
    }
  }
</script>
<style>
.critical-error-omg .fancybox-content{
  outline:3px solid red;
  padding: 20px;
}
</style>