<template>
  <div v-bind:class="['card', {'spoiler js-spoiler':collapsible, 'collapsed':!openAtStart}]">
    <div class="card-title">
      <div class="card-title-span">{{ title }}</div>
      <svg-icon name="spoiler-dropdown" v-if="collapsible"/>
    </div>
    <div class="card-content">
      <slot/>
    </div>
  </div>
</template>
<script>
import SvgIcon from "./SvgIcon.vue";

export default {
  components: {SvgIcon},
  props: {
    title: {
      type: String,
      required: true
    },
    collapsible: {
      type: Boolean,
      required: false,
      default: false
    },
    openAtStart: {
      type: Boolean,
      required: false,
      default: true
    }
  }
}
</script>