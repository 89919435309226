import dinero from "dinero.js";

/**
 * класс денег для проекта, который под капотом использует реализацию Dinero.js
 * так то в нем все хорошо кроме одного - конвертация валют через промисы, а хочется синхронно.
 * dinero работает с "центами" (копейкам) поэтому в паре мест тут перегон из рубле-копеек и обратно
 */
class Money {
    constructor(amount, currency) {
        this._implementation = dinero({amount: Math.round(amount * 100), currency:currency, precision: 4})
        if(undefined == currency){console.trace()}
    }

    getAmount() {
        return this._implementation.getAmount() * 0.01
    }

    getCurrency() {
        return this._implementation.getCurrency()
    }

    convert(toCurrency, options) {
        if (this.getCurrency() == toCurrency) {
            return this
        }
        let value = this.getAmount();

        let rates = options.rates || {};
        let rate1 = rates[this.getCurrency()];
        let rate2 = rates[toCurrency];
        return new Money( rate1 * (value / rate2), toCurrency)
    }
}

export default Money
