<template>
  <div class="pagination">
    <div class="pagination-links">
      <a
          v-if="getViewData.show_first"
          class="pagination-link"
          v-bind:href="getViewData.first"
          @click="toFirst"
      >
        <svg class="svg-icon svg-icon-page-first">
          <use xlink:href="/img/svg-sprite.svg#svg-icon-page-first"></use>
        </svg>
      </a>
      <a
          v-if="getViewData.show_prev"
          class="pagination-link"
          v-bind:href="getViewData.prev"
          @click="toPrev"
      >
        <svg class="svg-icon svg-icon-page-prev">
          <use xlink:href="/img/svg-sprite.svg#svg-icon-page-prev"></use>
        </svg>
      </a>
      <a
          v-for="page in getViewData.pages"
          class="pagination-link"
          v-bind:class="{active: page.active}"
          v-bind:href="page.link"
          @click="toPage($event, page.number)"
      >{{ page.text }}
      </a>
      <a
          v-if="getViewData.show_next"
          class="pagination-link"
          v-bind:href="getViewData.next"
          @click="toNext"
      >
        <svg class="svg-icon svg-icon-page-next">
          <use xlink:href="/img/svg-sprite.svg#svg-icon-page-next"></use>
        </svg>
      </a>
      <a
          v-if="getViewData.show_last"
          class="pagination-link"
          v-bind:href="getViewData.last"
          @click="toLast"
      >
        <svg class="svg-icon svg-icon-page-last">
          <use xlink:href="/img/svg-sprite.svg#svg-icon-page-last"></use>
        </svg>
      </a>
    </div>
    <div
        v-if="getViewData.show_pages"
        class="pagination-limit">
      <select class="" @change="changeLimit" v-model="thisLimit">
        <option v-for="variant in perPageVariants" :value="variant">{{ variant }}</option>
      </select>
      <div class="pagination-limit-text">Показано
        <span>&nbsp;{{ getViewData.range }}&nbsp;</span>
        из
        <span>&nbsp;{{ getViewData.total }}&nbsp;</span>
      </div>
    </div>
  </div>
</template>
<script>
import {ref} from "vue";

const windowSize = 5;
const uniq = (value, index, self) => self.indexOf(value) === index
const getTotalPages = (totalRecords, perPageCurrent) => Math.ceil(totalRecords / perPageCurrent)
/**
 * @param path
 * @param params {URLSearchParams}
 * @param page
 */
const generatePageLink = (path, params, page) => {
  if (page > 1) {
    params.append('page', page.toString())
  }
  // params.append('limit', limit)
  const param = params.toString()
  if (!param.length) return path
  return path + "?" + param
}

const getUrlParams = () => {
  const params = new URLSearchParams(window.location.search);
  params.delete("page")
  params.delete("limit")
  params.delete("ajax")
  return params
}
const getPath = () => '//' + location.host + location.pathname

const createPageNumbers = (path, totalRecords, perPageCurrent, currentPage) => {
  const totalPages = getTotalPages(totalRecords, perPageCurrent)

  if (1 == totalPages) {
    return [];
  }

  const pages = [1, totalPages];
  const windowStart = currentPage - Math.floor(windowSize / 2);
  const windowEnd = windowStart + windowSize;

  for (let i = windowStart; i < windowEnd; i++) {
    pages.push(i)
  }

  const outOfScope = pageNumber => (pageNumber>0 && pageNumber <= totalPages)
  const isNumber = pageNumber=>!isNaN(pageNumber)&&isFinite(pageNumber)

  const p = pages
      .filter(uniq)
      .filter(outOfScope)
      .filter(isNumber)
      .sort(intSort);
  return p
      .reduce((acc, pageNumber, k, arr) => {
        if (k > 0) {
          let $prevPageNum = arr[k - 1];
          if ((pageNumber - $prevPageNum) > 1) {
            // генерация промежуточных страниц с троеточиями по принципу "если есть большой пропуск делаем ссылку"
            let $middleNum = Math.floor((pageNumber + $prevPageNum) / 2);
            acc.push({
              'active': false,
              'text': "...",
              'number': $middleNum,
              'link': generatePageLink(path, getUrlParams(), $middleNum)
            })
          }
        }

        acc.push({
          'active': currentPage == pageNumber,
          'number': pageNumber,
          'text': pageNumber,
          'link': generatePageLink(path, getUrlParams(), pageNumber)
        });

        return acc;
      }, [])
}

const intSort = (a,b)=>a-b
export default {
  props:{
    limit:{type:Number,required:true},
    page:{type:Number,required:true},
    total:{type:Number,required:true},
  },
  data() {
    return {
      perPageVariants: [10, 20, 30],
      thisLimit:this.limit
    }
  },
  computed: {
    start() {
      return (this.page - 1) * this.limit
    },
    end() {
      return Math.min(this.page * this.limit, this.total)
    },
    isFirstPage() {
      return 1 == this.page
    },
    isLastPage() {
      return getTotalPages(this.total, this.limit) == this.page
    },
    getViewData() {
      const path = getPath()
      const totalPages = getTotalPages(this.total, this.limit);
      let $range;
      const rangeStart = (this.page - 1) * this.limit
      const rangeEnd = Math.min(rangeStart + this.limit, this.total)
      if (!rangeStart) {
        $range = [rangeEnd]
      } else {
        $range = [rangeStart, rangeEnd]
      }

      const pages = totalPages > 1
          ? createPageNumbers(path, this.total, this.limit, this.page)
          : []
      return {
        'show_first': !this.isFirstPage,
        'show_prev': !this.isFirstPage,
        'show_next': !this.isLastPage,
        'show_last': !this.isLastPage,

        'first': generatePageLink(path, getUrlParams(), 1),
        'prev': generatePageLink(path, getUrlParams(), this.page - 1),
        'next': generatePageLink(path, getUrlParams(), this.page + 1),
        'last': generatePageLink(path, getUrlParams(), totalPages),

        'show_pages': totalPages > 1,
        'pages': pages,
        'range': $range.join(" - "),
        'total': this.total
      }
    },

  },
  methods: {
    toFirst(e){
      e.preventDefault()
      this.trigger(this.limit, 1)
    },
    toPrev(e){
      e.preventDefault()
      this.trigger(this.limit,Math.max(1, this.page-1))
    },

    toLast(e){
      e.preventDefault()
      this.trigger(this.limit,getTotalPages(this.total, this.limit))
    },
    toNext(e){
      e.preventDefault()
      this.trigger(this.limit, Math.min(getTotalPages(this.total, this.limit), this.page+1))
    },
    toPage(e, num){
      e.preventDefault()
      this.trigger(this.limit, Math.min(
          getTotalPages(this.total, this.limit),
          Math.max(1, num)
      ))

    },
    changeLimit() {
      this.trigger(this.thisLimit, 1)
    },
    trigger(limit, page){
      this.$emit('changed', {
        limit: parseInt(limit),
        page: parseInt(page)
      })
    }
  }
}
</script>
