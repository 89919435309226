<template>
  <div class="grid-top">
    <form
        name="filter-form"
        class="filter-form"
        v-bind:action="fetchUrl"
        method="get"
        id="filter-form"
        autocomplete="off"
    >
      <Fieldset>
        <SearchableSelect
            ref="select"
            :value="type.value"
            @update:value="typeChange"
            :options="type.options"
            :label="type.label"
            :name="type.name"
            :key="selectKey"
            class="type"
        />
        <Search
            :value="search.value"
            @update:value="searchChange"
            :label="search.label"
            :name="search.name"
            :key="searchKey"
            class="search"
        />
        <button class="reset-form-link" type="reset" @click="reset">Сбросить</button>
      </Fieldset>
    </form>
    <a v-bind:href="addElementUrl" class="button button-outline active add-counterparty">Добавить контрагента</a>
  </div>
  <div v-bind:class="['table-container',  {'loading':loading}]">
    <table class="sortable-table">
      <thead>
      <tr>
        <th v-for="(col, i) in cols" :key="col.code" v-bind:class="[col.code]">
          <div
              v-bind:class="{'sortable-span': col.sortable, asc:col.sort=='asc', desc:col.sort=='desc'}"
              @click="changeSort(i)"
          >{{ col.label }}
          </div>
        </th>
      </tr>
      </thead>
      <tbody>
      <tr v-if="!items.length">
        <td v-bind:colspan="cols.length">Нет результатов</td>
      </tr>
      <tr v-for="(row, k) in items">
        <td class="short-name">
          <span class="label">
            <a v-bind:href="row.edit">{{row.label}}</a>
          </span>
        </td>
        <td>
          <span class="role" v-html="row.role"></span>
        </td>
        <td>
          <span class="inn" v-html="row.inn"></span>
        </td>
        <td>
          <span v-bind:class="['verify',{'bool-yes':row.verify, 'bool-no':!row.verify}]"
                v-html="[row.verify?'Заполнен':'Не заполнен']"></span>
        </td>
        <td>
          <span class="monitor" v-if="row.monitor">
            <svg class="svg-icon svg-icon-tick">
              <use xlink:href="/img/svg-sprite.svg#svg-icon-tick"></use>
            </svg>
          </span>
        </td>
        <td>
          <span class="calc">
            <a class="button" v-bind:href="row.calc">
              <svg class="svg-icon svg-icon-view">
                <use xlink:href="/img/svg-sprite.svg#svg-icon-view"></use>
              </svg>
            </a>
          </span>
        </td>
        <td>
          <span class="edit">
            <a class="button" v-bind:href="row.edit">
              <svg class="svg-icon svg-icon-edit">
                <use xlink:href="/img/svg-sprite.svg#svg-icon-edit"></use>
              </svg>
            </a>
          </span>
        </td>
      </tr>
      </tbody>
    </table>
  </div>
  <Pagination
      @changed="paged"
      v-bind:limit='pagination.limit'
      v-bind:page='pagination.page'
      v-bind:total='pagination.total'
  />
</template>
<script>
import Pagination from "../components/Pagination";
import axios from "axios";
import Search from "../components/form/Search";
import SearchableSelect from "../components/form/SearchableSelect";
import Fieldset from "../components/form/Fieldset";

const nextSort = (current) => {
  switch (current) {
    case 'none':
      return 'asc';
    case 'asc':
      return 'desc';
    case 'desc':
      return 'none';
  }
}
/**
 * @param columns {Array}
 * @returns {*}
 */
const getSort = (columns) => {
  return columns.filter((col) => {
    return col.sortable && col.sort != 'none';
  }).reduce((acc, col) => {
    return {sortItem: col.code, sortDirection: col.sort}
  }, {sortItem: '', sortDirection: 'none'})
}
let i = 0;
const nextId = () => i++
const makeParams = (sortParam, sortDirection, page, limit, search, type) => {
  const params = new URLSearchParams(window.location.search);
  params.delete('sort')
  params.delete('page')
  params.delete('limit')
  params.delete('search')
  params.delete('type')

  if ('none' !== sortDirection) {
    params.append('sort', `${sortParam}:${sortDirection}`)
  }
  if (page > 1) {
    params.append('page', page)
  }
  if (limit != 20) {
    params.append('limit', limit)
  }
  if (search.length) {
    params.append('search', search)
  }
  if (type.length) {
    params.append('type', type)
  }
  return params
}
const makeUrl = (url, params) => {
  return [url, params.toString()]
      .filter(s => s.length)
      .join('?')
}
export default {
  props: {
    fetchUrl: {type: String, required: true},
    addElementUrl: {type: String, required: true},
    initPagination: {type: Object, required: true},
    initCols: {type: Array, required: true},
    initItems: {type: Array, required: true},
    initSearch: {type: Object, required: true},
    initType: {type: Object, required: true},
  },
  data() {
    return {
      loading: false,
      requestId: nextId(),
      pagination: this.initPagination,
      cols: this.initCols,
      items: this.initItems,
      search: this.initSearch,
      type: this.initType,
      selectKey: 0,
      searchKey: 0
    }
  },
  mounted() {
    if (!this.items.length) {
      this.fetch()
    }
  },
  components: {Fieldset, SearchableSelect, Search, Pagination},
  methods: {
    reset(e) {
      e.preventDefault();
      e.stopPropagation();
      this.search = {
        label: this.search.label,
        name: this.search.name,
        value: ''
      }
      this.type = {
        label: this.type.label,
        name: this.type.name,
        value: '',
        options: this.type.options,
      }
      this.pagination = {
        page: 1,
        limit: this.pagination.limit,
        total: this.pagination.total
      }
      this.selectKey += 1
      this.searchKey += 1
      this.fetch()
    },
    typeChange(newValue) {
      this.type = {
        label: this.type.label,
        name: this.type.name,
        value: newValue,
        options: this.type.options,
      }
      this.pagination = {
        page: 1,
        limit: this.pagination.limit,
        total: this.pagination.total
      }
      this.fetch()
    },
    searchChange(newValue) {
      this.search = {
        label: this.search.label,
        name: this.search.name,
        value: newValue
      }
      this.pagination = {
        page: 1,
        limit: this.pagination.limit,
        total: this.pagination.total
      }
      this.fetch()
    },
    changeSort(colIndex) {
      this.cols = this.cols.map((v, i) => {
        return i == colIndex && v.sortable
            ? (v.sort = nextSort(v.sort), v)
            : (v.sort = 'none', v)
      })
      this.fetch()
    },
    paged({limit, page}) {
      this.pagination = {
        limit: limit,
        page: page,
        total: this.pagination.total
      }
      this.fetch()
    },
    fetch() {
      this.requestId = nextId()
      const id = this.requestId + 0
      this.loading = true
      const {sortItem, sortDirection} = getSort(this.cols)
      const params = makeParams(sortItem, sortDirection, this.pagination.page, this.pagination.limit, this.search.value, this.type.value)
      const url = makeUrl(this.fetchUrl, params)
      axios
          .get(url)
          .then((result) => {
            if (id != this.requestId) return
            this.items = result.data.data
            const windowUrl = new URL(window.location.href);
            windowUrl.search = params
            window.history.pushState(JSON.stringify(this.items), '', windowUrl.toString())
            this.pagination = {
              limit: this.pagination.limit,
              total: result.data.pagination.total,
              page: this.pagination.page
            }
          })
          .catch((e) => {
            console.error(e)
          })
          .finally(() => {
            if (id == this.requestId) {
              this.loading = false
            }
          })
    }
  }
}
</script>

<style scoped>
.add-counterparty {
  margin-top: 5px;
}

table {
  background-color: #fff;
  box-shadow: 0px 0px 20px 0px rgba(56, 60, 97, 0.1);
}

.table-container {
  position: relative;
  z-index: 1;

}

.table-container:after {
  content: "";
  display: block;
  position: absolute;
  left: 0;
  right: 0;
  top: 0;
  bottom: 0;
  background-color: #fff;
  opacity: 0;
  pointer-events: none;
  z-index: -1;
}

.table-container.loading:after {
  /*z-index: 1;*/
  /*opacity: .5;*/
  /*pointer-events: all;*/
}

td[colspan] {
  text-align: center;
}

span.label {
  font-size: 14px;
  font-weight: 500;
  line-height: 17px;
}

span.verify {
  color: white;
  position: relative;
  left: -10px;
  display: inline-block;
  white-space: nowrap;
  height: 20px;
  line-height: 20px;
  min-width: 110px;
  font-style: normal;
  font-weight: bold;
  text-align: center;
}

span.verify.bool-yes {
  background: #1DC9B7;
  border-radius: 20px;
}

span.verify.bool-no {
  background: #FD397A;
  box-shadow: 0px 2px 4px rgba(60, 67, 119, 0.2);
  border-radius: 20px;
}

span.monitor {
  color: rgba(17, 148, 243, 1)
}

span.monitor svg {
  margin: auto;
}

span.calc svg, span.edit svg {
  color: #6B7888;
  margin: auto;
}

th {
  white-space: nowrap;
  width: 100%;
  min-height: 40px;
  font-size: 12px;
  font-weight: bold;
  line-height: 15px;
  color: rgba(126, 135, 148, 1);
  vertical-align: bottom;
  padding-bottom: 6px;
}

td {
  font-size: 12px;
  padding-bottom: 15px;
  line-height: 15px;
  color: rgba(64, 80, 99, 1);
  min-height: 50px;
}

thead {
  border-bottom: 2px solid rgba(227, 230, 243, 1);
}

tbody tr {
  border-bottom: 1px solid rgba(230, 234, 236, 1);
}

th, td {
  padding-top: 18px;
  padding-left: 18px;
  padding-right: 18px;
}
td.short-name{
  position: relative;
}
td.short-name span.label a{
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  padding: 18px;
  display: block;
  color:rgba(64, 80, 99, 1);
  text-decoration: none;
  border-bottom: 0 !important;
}
.sortable-span {
  display: inline-block;
  padding-right: 34px;
  background-position: right center;
  cursor: pointer;
  position: relative;
  z-index: 1;
  user-select: none;
}

.sortable-span.asc:after,
.sortable-span.desc:after {
  /*noinspection CssUnknownTarget*/
  background-image: url("/img/icons/arrow-sort.svg");
}

.sortable-span.desc:after {
  transform: rotate(180deg);
}

.sortable-span:after {
  content: "";
  display: block;
  background-repeat: no-repeat;
  background-position: right center;
  transform-origin: center center;
  background-image: none;
  width: 10px;
  height: 8px;
  position: absolute;
  right: 0;
  bottom: 0;
  top: 0;
  left: auto;
  margin: auto;
}
.sortable-table tr{
  transition: all .2s ease;
}
.sortable-table tbody tr:hover{
  background: #f2f7fc;
}
.search, .type {
  width: 195px;
}
</style>
