import { render } from "./BaseInfo.vue?vue&type=template&id=d5071652&scoped=true"
import script from "./BaseInfo.vue?vue&type=script&lang=js"
export * from "./BaseInfo.vue?vue&type=script&lang=js"

import "./BaseInfo.vue?vue&type=style&index=0&id=d5071652&scoped=true&lang=css"
script.render = render
script.__scopeId = "data-v-d5071652"
/* hot reload */
if (module.hot) {
  script.__hmrId = "d5071652"
  const api = __VUE_HMR_RUNTIME__
  module.hot.accept()
  if (!api.createRecord('d5071652', script)) {
    api.reload('d5071652', script)
  }
  
  module.hot.accept("./BaseInfo.vue?vue&type=template&id=d5071652&scoped=true", () => {
    api.rerender('d5071652', render)
  })

}

script.__file = "src/js/counterparty/BaseInfo.vue"

export default script