import { render } from "./Contact.vue?vue&type=template&id=2df0cfd0&scoped=true"
import script from "./Contact.vue?vue&type=script&lang=js"
export * from "./Contact.vue?vue&type=script&lang=js"

import "./Contact.vue?vue&type=style&index=0&id=2df0cfd0&scoped=true&lang=css"
script.render = render
script.__scopeId = "data-v-2df0cfd0"
/* hot reload */
if (module.hot) {
  script.__hmrId = "2df0cfd0"
  const api = __VUE_HMR_RUNTIME__
  module.hot.accept()
  if (!api.createRecord('2df0cfd0', script)) {
    api.reload('2df0cfd0', script)
  }
  
  module.hot.accept("./Contact.vue?vue&type=template&id=2df0cfd0&scoped=true", () => {
    api.rerender('2df0cfd0', render)
  })

}

script.__file = "src/js/counterparty/Contact.vue"

export default script