<template>
	<div class="flex-row">
		<slot/>
	</div>
</template>
<style scoped>
	.flex-row{
		display: flex;
		flex-direction: row;
		justify-content: flex-start;
	}
</style>
<script>
  export default {
    props:{}
  }
</script>