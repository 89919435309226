function buildToast(content, autoClose) {
    let options = {
        class: 'toast',
        html: [
            $('<div/>', {
                class: 'toast-content',
                html:content
            })
        ]
    };

    if (!autoClose) {
        options.html.push($('<div/>', {
            class: 'toast-close'
        }))
    } else {
        options.class += " autoclose"
    }
    return $('<div/>', options).hide()
}

function closeToast(toast) {
    if (typeof toast !== 'jQuery') {
        toast = $(toast)
    }
    if (!toast.is('.toast')) {
        toast = toast.closest('.toast')
    }
    if (!toast.length) return
    toast
        .addClass('closing')
        .slideToggle(300, function () {
            toast.remove()
        })
}

export default function ($document) {
    let $toastContainer = $document.find('.toast-container');
    if (!$toastContainer.length) {
        $toastContainer = $('<div class="toast-container"/>').appendTo('body')
    }
    $document.on('click', '[data-toast]', function (e) {
        e.preventDefault();
        const $button = $(e.currentTarget)
        $('<div/>', {
            html: $button.attr('data-toast')
        }).showAsToast(!!$button.attr('data-autoclose'))
    })
    $toastContainer.on('click', '.toast-close', function (e) {
        closeToast(e.currentTarget)
    })
    jQuery.fn.extend({
        showAsToast: function (autoClose) {
            const $this = $(this);
            const $toast = buildToast($this, autoClose)
            $toastContainer.prepend($toast)
            $toast.slideToggle(300, function () {
                $toast.addClass('open')
            })
            if (autoClose) {
                var delay     = 3300,
                    skipDelay = false,
                    timingFunction;
                const interval = 50;
                $toast.hover(function () {
                    skipDelay = true
                }, function () {
                    skipDelay = false
                })
                timingFunction = function () {
                    if (skipDelay) return;
                    if (delay <= 0) {
                        closeToast($toast)
                        clearInterval(timingFunction)
                        return
                    }
                    delay -= interval;
                }
                setInterval(timingFunction, interval)
            }
            return this
        }
    })
}
