import { render } from "./SimpleForm.vue?vue&type=template&id=6acc0365"
import script from "./SimpleForm.vue?vue&type=script&lang=js"
export * from "./SimpleForm.vue?vue&type=script&lang=js"
script.render = render
/* hot reload */
if (module.hot) {
  script.__hmrId = "6acc0365"
  const api = __VUE_HMR_RUNTIME__
  module.hot.accept()
  if (!api.createRecord('6acc0365', script)) {
    api.reload('6acc0365', script)
  }
  
  module.hot.accept("./SimpleForm.vue?vue&type=template&id=6acc0365", () => {
    api.rerender('6acc0365', render)
  })

}

script.__file = "src/js/components/form/SimpleForm.vue"

export default script