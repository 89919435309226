<template>
  <div class="form-control form-control-checkbox form-control-checkbox-multiple">
    <div class="label-title">{{ label }}</div>
    <label v-for="ch in items">
      <input type="checkbox" v-bind:checked="ch.checked" v-bind:name="name" v-bind:value="ch.value"/>
      <span class="checkbox-span"></span>
      <span class="label-title">{{ ch.label }}</span>
    </label>
  </div>
</template>
<script>
export default {
  props: {
    label: String,
    name:String,
    items: Array
  }
}
</script>