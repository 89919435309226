<template>
  <div
      v-bind:class="[
          'form-control form-control-text',
          {
            'has-value':value.toString().trim().length>0,
            'readonly':$attrs.readonly||false,
            'error': errors.length>0
          }
          ]">
    <label ref="label" class="form-control-text-label not-ready" v-bind:data-label="$attrs.label||''">
      <input
          :type="$attrs.type||'text'"
          ref="input"
          class="no-label-interaction"
          v-bind:value="value"
          @change="$emit('update:value', $event.target.value)"
          @input="$emit('update:value', $event.target.value)"
          v-inputMask="$attrs.mask||''"
          v-bind:readonly="$attrs.readonly||false"
      >
      <span class="label-title floating-label-title">{{ $attrs.label || '' }}</span>
    </label>
    <ul class="parsley-errors-list" v-if="errors.length>0">
      <li v-for="e in errors">{{ e }}</li>
    </ul>
  </div>
</template>
<script>
import {errorSupport} from "../../mixin/error-support";

export default {
  mixins: [errorSupport],
  props: {
    value: {
      type: String, required: false
    },
  },
  emits: ['update:value']
}
</script>