<template>
	<form v-bind:class="[{'loading':loading}]"
	      :action="action"
	      autocomplete="off"
	      :method="$attrs.method || 'post'"
	      novalidate="">
		<slot/>
	</form>
</template>
<script>
  export default {
    props: {
      action: String,
      loading: Boolean
    }
  }
</script>