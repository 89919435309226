import {EVENT_BINDING_REQUIRED}                from "./events";
import {validate}                              from "../plugins/parsley.js/validator";
import {cleanNumericValue, setValueAndMaskIt}  from "../plugins/jquery-mask/mask";
import {onJsonError, onJsonSuccess}            from "./json-api";
import {disableSelect, enableSelect, replaceOptions, setValue} from "../plugins/choices.js/js/choices";
import Money                                   from "./money";

const translateCurrency = (currency)=>{
    switch (currency) {
        case 'RUB': return '₽'
        case 'EUR': return '€'
        case 'USD': return '$'
    }
    return null
}
let $form;
let courses = {}

let salePrice = 0,
    $salePrice;

let saleCurrency = "";

let currencyCourse = 0,
    $currencyCourse;

let leasingCurrency = "";

let leasingPrice = 0,
    $leasingPrice;

let prepaymentPercent = 0,
    $prepaymentPercent,
    prepaymentValue   = 0,
    $prepaymentValue;

let comissionPercent = 0,
    $comissionPercent,
    comissionValue   = 0,
    $comissionValue;

let leasingTime,
    $leasingTime;

let finAmount = 0,
    $finAmount;

let residueAmount,
    $residueAmount,
    residuePercent,
    $residuePercent

let $rvbp;

let cof       = 0,
    $cof,
    margin    = 0,
    $margin,
    totalRate = 0,
    $totalRate;

let shadowDiscountValue   = 0,
    shadowDiscountPercent = 0,
    $discount,
    $discountType;

/**
 * @type {Money}
 */
let shadowSubsidyValue,
    shadowSubsidyPercent = 0,
    $subsidy,
    $subsidyType;

let agentCommissionValueAmount = 0,
    agentCommissionValuePercent = 0,
    $agentCommissionValueAmount,
    $agentCommissionValuePercent

const leasingAfterDiscount = ()=> {
    return (100 - shadowDiscountPercent) * 0.01 * leasingPrice
}
const saleAfterDiscount = ()=>{
    return (100 - shadowDiscountPercent) * 0.01 * salePrice
}
function initSubsidy($form) {
    let $_subsidy = $form.find('#calculator_form_subsidyValue')
    let $_subsidyType = $form.find('#calculator_form_subsidyType')
    if(!$_subsidy.length || !$_subsidyType.length) return;

    $subsidy = $_subsidy;
    $subsidyType=$_subsidyType;

    if ($subsidyType.val() == 'percent') {
        shadowSubsidyPercent = cleanNumericValue($subsidy)
        // субсидия в случае "процентов" будет в валюте дкп (sale)
        shadowSubsidyValue = new Money(
            0.01 * shadowSubsidyPercent * saleAfterDiscount(),
            saleCurrency
        )
    } else {
        // в случае когда явно указана субсидия - храним ее в этой валюте, и по необходимости конвертируем
        shadowSubsidyValue = new Money(
            cleanNumericValue($subsidy),
            $subsidyType.val()
        )

        shadowSubsidyPercent = salePrice > 0
                               ? (100 * shadowSubsidyValue / saleAfterDiscount())
                               : 0
    }
}

function init($root) {
    $form = $root;
    courses.RUB = 1;
    courses.USD = parseFloat($form.attr('data-usd'));
    courses.EUR = parseFloat($form.attr('data-eur'));

    $salePrice = $form.find('#calculator_form_priceSale');
    salePrice = cleanNumericValue($salePrice);

    saleCurrency = $form.find('.form-name-currency-dkp :checked').val();

    $currencyCourse = $form.find('#calculator_form_currencyCourse');
    currencyCourse = cleanNumericValue($currencyCourse);

    leasingCurrency = $form.find('.form-name-currency-leasing :checked').val();

    $leasingPrice = $form.find('#calculator_form_priceLeasing');
    leasingPrice = cleanNumericValue($leasingPrice);

    $prepaymentPercent = $form.find('#calculator_form_prepaymentPercent')
    prepaymentPercent = cleanNumericValue($prepaymentPercent)
    $prepaymentValue = $form.find('#calculator_form_prepaymentAmount')
    prepaymentValue = cleanNumericValue($prepaymentValue)

    $comissionPercent = $form.find('#calculator_form_commissionPercent')
    comissionPercent = cleanNumericValue($comissionPercent)
    $comissionValue = $form.find('#calculator_form_commissionValue')
    comissionValue = cleanNumericValue($comissionValue)

    $leasingTime = $form.find('#calculator_form_leasingTimeMonths')
    leasingTime = cleanNumericValue($leasingTime);

    $finAmount = $form.find('#calculator_form_finAmount')
    finAmount = cleanNumericValue($finAmount)

    $residueAmount = $('#calculator_form_residualUnitValueAmount');
    residueAmount = cleanNumericValue($residueAmount)
    $residuePercent = $('#calculator_form_residualUnitValuePercent');
    residuePercent = cleanNumericValue($residuePercent)

    $rvbp = $('.form-name-rv-bp input');

    $cof = $form.find('#calculator_form_cof')
    cof = cleanNumericValue($cof)
    $margin = $form.find('#calculator_form_margin')
    margin = cleanNumericValue($margin)
    $totalRate = $form.find('#calculator_form_totalRate')
    totalRate = cleanNumericValue($totalRate)

    $discount = $form.find('#calculator_form_discountValue')
    $discountType = $form.find('#calculator_form_discountType')
    if ($discountType.val() == 'percent') {
        shadowDiscountPercent = cleanNumericValue($discount)
        shadowDiscountValue = 0.01 * shadowDiscountPercent * salePrice
    } else {
        shadowDiscountValue = cleanNumericValue($discount)
        shadowDiscountPercent = salePrice > 0
                                ? (100 * shadowDiscountValue / salePrice)
                                : 0
    }

    initSubsidy($form)

    $agentCommissionValueAmount = $form.find('#calculator_form_agentCommissionValueAmount')
    $agentCommissionValuePercent = $form.find('#calculator_form_agentCommissionValuePercent')
    agentCommissionValueAmount = cleanNumericValue($agentCommissionValueAmount)
    agentCommissionValuePercent = cleanNumericValue($agentCommissionValuePercent)

    $form.find('#calculate').on('click', validateAndUpdate)
    $form.on('change input', onChangeInput)
    $form.on('autocomplete-change', function (e) {
        let $formControl = $(e.target);
        switch ($formControl.attr('id')) {
            case 'calculator_form_productId':
                updateZones(['.form-name-type', '.form-name-brand'], { control_id: $formControl.attr('id') })
                break
        }
    })

    toggleRvBp($('#calculator_form_residualPaymentOrder'))
}

function validateAndUpdate(e) {
    e.preventDefault();
    e.stopPropagation();
    validate($form).done(() => {
        $form.trigger('update')
    })
}

function onChangeInput(e) {
    let $formControl = $(e.target);
    if ('change' == e.type) {
        // на изменении срабатывают выпадающие списки
        switch ($formControl.attr('id')) {
            case 'calculator_form_leasingProgram':
                updateZones(['.form-name-product'], { control_id: $formControl.attr('id') })
                break;
            case 'calculator_form_leasingProduct':
                updateZones(['.form-name-leasing'], { control_id: $formControl.attr('id') })
                break;

            case 'calculator_form_year':
                $('#calculator_form_used')
                    .prop('checked', (new Date()).getFullYear() != parseInt(cleanNumericValue($formControl)))
                break;
            case 'calculator_form_brand':
                updateZones(['.form-name-type', '.form-name-model'], { control_id: $formControl.attr('id') })
                break;
            case 'calculator_form_type':
                updateZones(['.form-name-brand', '.form-name-model'], { control_id: $formControl.attr('id') })
                break;
            case 'calculator_form_paymentStrategy':
                updateZones(['.seasonal-schedule-zone'], { control_id: $formControl.attr('id') });
                break;
            case 'calculator_form_seasonalDateStart':
            case 'calculator_form_seasonalCyclic':
                updateZones(['.seasonal-schedule-zone'], { control_id: $formControl.attr('id') });
                break;
            case 'calculator_form_insuranceIncludedInCalculation':
                if ($formControl.prop('checked')) {
                    removeReadonly($('#calculator_form_insuranceCompany, #calculator_form_insurancePercent, #calculator_form_franchiseInsurance'))
                    if('none'==$('#calculator_form_franchiseInsurance').val()){
                        setReadonly($('#calculator_form_franchiseAmount'))
                    }else{
                        removeReadonly($('#calculator_form_franchiseAmount'))
                    }
                } else {
                    setReadonly($('#calculator_form_insuranceCompany, #calculator_form_insurancePercent, #calculator_form_franchiseInsurance, #calculator_form_franchiseAmount'))
                }
                break;
            case 'calculator_form_rvEnabled':
                if ($formControl.prop('checked')) {
                    removeReadonly($('#calculator_form_rvValue'))
                } else {
                    setReadonly($('#calculator_form_rvValue'))
                    setValueAndMaskIt($('#calculator_form_rvValue'), 0.0)
                }
                break;
            case 'calculator_form_leasingPropertyTaxEnabled':
                if ($formControl.prop('checked')) {
                    removeReadonly($('#calculator_form_leasingPropertyTaxPercent'))
                } else {
                    setReadonly($('#calculator_form_leasingPropertyTaxPercent'))
                }
                break;
            case 'calculator_form_residualPaymentOrder':
                toggleRvBp($formControl)
                break;
            case 'calculator_form_leasingTimeMonths':
                recalculateCof();
                updateZones(['.seasonal-schedule-zone'], { control_id: $formControl.attr('id') });
                break;
            case 'calculator_form_franchiseInsurance':
                if('none'==$formControl.val()){
                    setReadonly($('#calculator_form_franchiseAmount'))
                }else{
                    removeReadonly($('#calculator_form_franchiseAmount'))
                }
                break;
            case 'calculator_form_reverse':(($irr, $overprice)=>{
                setValueAndMaskIt($overprice, 0.0)
                setValueAndMaskIt($irr, 0.0)
                switch ($formControl.val()){
                    case 'irr':
                        removeReadonly($irr)
                        setReadonly($overprice)
                        setReadonly($subsidy)
                        setReadonly($subsidyType)
                        break;
                    case 'overprice':
                        setReadonly($irr)
                        removeReadonly($overprice)
                        setReadonly($subsidy)
                        setReadonly($subsidyType)
                        break;
                    default:
                        setReadonly($irr)
                        setReadonly($overprice)
                        removeReadonly($subsidy)
                        removeReadonly($subsidyType)
                        break;
                }
            })(
                $('#calculator_form_reverseIrr'),
                $('#calculator_form_reverseOverprice')
            )
                break;
        }

        let $parent = $formControl.closest('.form-control');

        if ($parent.is('.form-name-currency-dkp')) {
            // меняется валюта дкп - пересчитываем цену от стоимости по дцп
            saleCurrency = $formControl.val();

            salePrice = cleanNumericValue($salePrice)
            if(saleCurrency =='RUB' && leasingCurrency == 'RUB'){
                currencyCourse = 1
                leasingPrice = salePrice
            }else if(saleCurrency == 'RUB'){
                currencyCourse = courses[leasingCurrency]
                leasingPrice = salePrice / currencyCourse
            }else if(leasingCurrency == 'RUB'){
                currencyCourse = courses[saleCurrency]
                leasingPrice = salePrice * currencyCourse
            }else{
                leasingPrice = (salePrice * courses[saleCurrency]) / currencyCourse
            }

            const opts = {}
            opts[translateCurrency(saleCurrency)] = saleCurrency
            opts['%'] = 'percent'
            replaceOptions($discountType.get(0), opts)
            setValue($discountType.get(0), 'percent' == $discountType.val()?'percent':saleCurrency)

            onPrepaymentChange(true)
            onComissionChange(true)
            onResidueChange(true)
            recalculateDiscount()
            recalculateAgentCommission(true)
            updateZones(['.form-name-subsidy-combined', '.form-name-rv-type-combined'], { control_id: $formControl.attr('id') })


        }

        if ($parent.is('.form-name-currency-leasing')) {
            // меняется валюта лизинга - подставляем курс в поле курса перерасчета и пересчитываем цену
            leasingCurrency = $formControl.val();
            salePrice = cleanNumericValue($salePrice)

            if(saleCurrency =='RUB' && leasingCurrency == 'RUB'){
                currencyCourse = 1
                leasingPrice = salePrice
            }else if(leasingCurrency == 'RUB'){
                // лизинг перешел на рубли но дкп в другой валюте
                currencyCourse = courses[saleCurrency]
                leasingPrice = salePrice * currencyCourse
            }else if(saleCurrency == 'RUB'){
                // дкп в рублях а лизинг перешел на другую валюту
                currencyCourse = courses[leasingCurrency]
                leasingPrice = salePrice / currencyCourse
            }else{
                // Обе валюты не рубль, значит берем новый курс из настроек
                currencyCourse = courses[leasingCurrency]
                leasingPrice = (salePrice * courses[saleCurrency]) / currencyCourse
            }

            onPrepaymentChange(true)
            onComissionChange(true)
            onResidueChange(true)
            // состояние чекбокса меняется только при изменении валюты лизинга
            $('#calculator_form_conversionCommission').prop('checked', leasingCurrency != 'RUB')
            recalculateDiscount()
            updateZones(['.form-name-subsidy-combined','.form-name-rv-type-combined'], { control_id: $formControl.attr('id') })
            recalculateCof()
            recalculateAgentCommission(true)
        }

        if($parent.is('.form-name-paid')){
            $formControl.closest('tr').find('.form-name-preferential, .form-name-coefficient').toggleClass('disabled', !$formControl.is(":checked"))
        }
    }

    switch ($formControl.attr('id')) {
        case 'calculator_form_priceSale':
            onSalePriceChange()
            break;
        case 'calculator_form_currencyCourse':
            onCurrencyCourseChange()
            break;
        case 'calculator_form_priceLeasing':
            onLeasingPriceChange()
            break;
        case 'calculator_form_prepaymentPercent':
            onPrepaymentChange(true)
            break
        case 'calculator_form_prepaymentAmount':
            onPrepaymentChange(false)
            break;
        case 'calculator_form_commissionPercent':
            onComissionChange(true)
            break
        case 'calculator_form_commissionValue':
            onComissionChange(false)
            break;
        case 'calculator_form_residualUnitValuePercent':
            onResidueChange(true)
            break;
        case 'calculator_form_residualUnitValueAmount':
            onResidueChange(false)
            break;
        case 'calculator_form_cof':
        case 'calculator_form_margin':
            onCofMarginChange()
            break;
        case 'calculator_form_discountValue':
            if ($discountType.val() == 'percent') {
                shadowDiscountPercent = cleanNumericValue($discount)
                recalculateDiscount()
            } else {
                shadowDiscountValue = cleanNumericValue($discount)
                shadowDiscountPercent = salePrice > 0
                                        ? 100 * shadowDiscountValue / salePrice
                                        : 0
            }
            onSalePriceChange()
            break;
        case 'calculator_form_subsidyType':
            shadowSubsidyPercent = 0.0;
            shadowSubsidyValue = new Money(
                0,
                saleCurrency
            )
            break;
        case 'calculator_form_subsidyValue':
            if ($subsidyType.val() == 'percent') {
                shadowSubsidyPercent = cleanNumericValue($subsidy)
                recalculateSubsidy()
            } else {
                shadowSubsidyValue = new Money(
                    cleanNumericValue($subsidy),
                    $subsidyType.val()
                )
                shadowSubsidyPercent = leasingPrice > 0
                                       ? 100 * shadowSubsidyValue / saleAfterDiscount()
                                       : 0
            }
            break;
        case 'calculator_form_agentCommissionValueAmount':
            recalculateAgentCommission(false);
            break;
        case 'calculator_form_agentCommissionValuePercent':
            recalculateAgentCommission(true);
            break;
    }
    render()
}

/**
 * перезагружаем с бэкенда несколько кусков формы
 * @param zones
 * @param extra
 */
function updateZones(zones, extra = {}) {
    let params = $.extend({}, {zones: zones}, extra)
    $.ajax({
        url: $form.attr('data-update-zones-url') + "?" + $.param(params),
        data: $form.serialize(),
        type: "POST",
        beforeSend: function () {
            zones.forEach(v => $(v).addClass('loading'))
        },
        complete: function () {
            zones.forEach(v => $(v).removeClass('loading'))
        },
        success: function (response, textStatus, jqXHR) {
            onJsonSuccess($form, response, textStatus, jqXHR)
        },
        error: function (jqXHR, textStatus, errorThrown) {
            onJsonError($form, jqXHR, textStatus, errorThrown)
        }
    });
}

function onSalePriceChange() {
    salePrice = cleanNumericValue($salePrice)
    currencyCourse = cleanNumericValue($currencyCourse)

    if(saleCurrency =='RUB' && leasingCurrency == 'RUB'){
        leasingPrice = salePrice * currencyCourse
    }else if(leasingCurrency == 'RUB'){
        // лизинг в рублях но дкп в другой валюте - значит умножаем на курс
        leasingPrice = salePrice * currencyCourse
    }else if(saleCurrency == 'RUB'){
        // дкп в рублях а лизинг в другой валюте - значит делим на курс
        leasingPrice = salePrice / currencyCourse
    }else{
        // Обе валюты не рубль, значит берем левый курс из настроек, а правый из значения
        leasingPrice = (salePrice * courses[saleCurrency]) / currencyCourse
    }

    onPrepaymentChange(true)
    onComissionChange(true)
    onResidueChange(true)
    recalculateDiscount()
    recalculateSubsidy()
    recalculateAgentCommission(true)
}

function onCurrencyCourseChange() {
    onSalePriceChange()
}

function onLeasingPriceChange() {
    leasingPrice = cleanNumericValue($leasingPrice)
    currencyCourse = cleanNumericValue($currencyCourse)

    if(saleCurrency =='RUB' && leasingCurrency == 'RUB'){
        salePrice = leasingPrice * currencyCourse
    }else if(leasingCurrency == 'RUB'){
        // лизинг в рублях но дкп в другой валюте - значит делим на курс
        salePrice = leasingPrice / currencyCourse
    }else if(saleCurrency == 'RUB'){
        // дкп в рублях а лизинг в другой валюте - значит умножаем на курс
        salePrice = leasingPrice * currencyCourse
    }else{
        // Обе валюты не рубль, значит берем левый курс из настроек, а правый из значения
        salePrice = (leasingPrice * currencyCourse) / courses[saleCurrency]
    }

    onPrepaymentChange(true)
    onComissionChange(true)
    onResidueChange(true)
    recalculateDiscount()
    recalculateSubsidy()
    recalculateAgentCommission(true)
}

function onPrepaymentChange(percentChanged) {
    let leasingPriceWithDiscount = leasingAfterDiscount()
    if (percentChanged) {
        // меняли процент - считаем значение
        prepaymentPercent = cleanNumericValue($prepaymentPercent)
        prepaymentValue = leasingPriceWithDiscount * prepaymentPercent * 0.01
    } else {
        prepaymentValue = cleanNumericValue($prepaymentValue)
        let maxPercent = parseFloat($prepaymentPercent.attr('data-max')) * 0.01
        if (prepaymentValue > maxPercent * leasingPriceWithDiscount) {
            prepaymentValue = maxPercent * leasingPriceWithDiscount
        }
        prepaymentPercent = 100 * (prepaymentValue / leasingPriceWithDiscount)
    }
    finAmount = leasingPriceWithDiscount - prepaymentValue
}

function onComissionChange(percentChanged) {
    let leasingPriceWithDiscount = leasingAfterDiscount()
    if (percentChanged) {
        // меняли процент - считаем значение
        comissionPercent = cleanNumericValue($comissionPercent)
        comissionValue = leasingPriceWithDiscount * comissionPercent * 0.01
    } else {
        comissionValue = cleanNumericValue($comissionValue)
        let maxPercent = parseFloat($comissionPercent.attr('data-max')) * 0.01
        if (comissionValue > maxPercent * leasingPriceWithDiscount) {
            comissionValue = maxPercent * leasingPriceWithDiscount
        }
        comissionPercent = 100 * (comissionValue / leasingPriceWithDiscount)
    }
}

function onResidueChange(percentChanged) {
    let leasingPriceWithDiscount = leasingAfterDiscount()
    if (percentChanged) {
        // меняли процент - считаем значение
        residuePercent = cleanNumericValue($residuePercent)
        residueAmount = leasingPriceWithDiscount * residuePercent * 0.01
    } else {
        residueAmount = cleanNumericValue($residueAmount)
        let maxPercent = parseFloat($comissionPercent.attr('data-max') || 100) * 0.01
        if (residueAmount > maxPercent * leasingPriceWithDiscount) {
            residueAmount = maxPercent * leasingPriceWithDiscount
        }
        residuePercent = 100 * (residueAmount / leasingPriceWithDiscount)
    }
}

function recalculateDiscount() {
    if('percent' == $discountType.val()){
        shadowDiscountValue = salePrice * 0.01 * shadowDiscountPercent
    }else{
        shadowDiscountPercent = salePrice > 0
                                      ? 100 * shadowDiscountValue / salePrice
                                      : 0
        if(shadowDiscountPercent>100){
            shadowDiscountPercent = 100;
            shadowDiscountValue = salePrice;
        }
    }
}

function recalculateSubsidy() {
    const salePriceAfterDiscount = saleAfterDiscount()
    if($subsidyType.val()=='percent'){
        const money = new Money(salePriceAfterDiscount * 0.01 * shadowSubsidyPercent, saleCurrency);
        shadowSubsidyValue = money.convert(shadowSubsidyValue.getCurrency(), {rates: courses})
    }else{
        shadowSubsidyPercent = leasingPrice > 0
                                ? 100
                                   * shadowSubsidyValue.convert(leasingCurrency, {rates: courses}).getAmount()
                                   / salePriceAfterDiscount
                                : 0
        if(shadowSubsidyPercent>100){
            shadowSubsidyPercent = 100;
            shadowSubsidyValue = new Money(salePriceAfterDiscount, leasingCurrency).convert(shadowSubsidyValue.getCurrency(), {rates: courses});
        }
    }
}

function recalculateAgentCommission(percentChanged) {
    let leasingPriceWithDiscount = leasingAfterDiscount()
    if (percentChanged) {
        // меняли процент - считаем значение
        agentCommissionValuePercent = cleanNumericValue($agentCommissionValuePercent)
        agentCommissionValueAmount = leasingPriceWithDiscount * agentCommissionValuePercent * 0.01
    } else {
        agentCommissionValueAmount = cleanNumericValue($agentCommissionValueAmount)
        let maxPercent = parseFloat($agentCommissionValuePercent.attr('data-max')) * 0.01
        if (agentCommissionValueAmount > maxPercent * leasingPriceWithDiscount) {
            agentCommissionValueAmount = maxPercent * leasingPriceWithDiscount
        }
        agentCommissionValuePercent = 100 * (agentCommissionValueAmount / leasingPriceWithDiscount)
    }
}

function onCofMarginChange() {
    cof = cleanNumericValue($cof)
    margin = cleanNumericValue($margin)
    totalRate = cof + margin
}
function recalculateCof() {
    let year;
    leasingTime = parseInt($leasingTime.val())
    if(leasingTime<=13){
        year=1
    }else if(leasingTime<=24){
        year=2
    }else if(leasingTime<=36){
        year=3
    }else if(leasingTime<=48){
        year=4
    }else{
        year=5
    }
    if(calculator_settings && calculator_settings.cof && calculator_settings.cof[leasingCurrency]){
        cof = calculator_settings.cof[leasingCurrency][year]
        margin = cleanNumericValue($margin)
        totalRate = cof + margin
    }
}
function render() {
    const data = [
        [
            $salePrice,
            salePrice
        ],
        [
            $currencyCourse,
            currencyCourse
        ],
        [
            $leasingPrice,
            leasingPrice
        ],
        [
            $prepaymentPercent,
            prepaymentPercent
        ],
        [
            $prepaymentValue,
            prepaymentValue
        ],
        [
            $comissionPercent,
            comissionPercent
        ],
        [
            $comissionValue,
            comissionValue
        ],
        [
            $finAmount,
            finAmount
        ],
        [
            $residueAmount,
            residueAmount
        ],
        [
            $residuePercent,
            residuePercent
        ],
        [
            $cof,
            cof
        ],
        [
            $totalRate,
            totalRate
        ],
        [
            $discount,
            'percent' == $discountType.val()
                ? shadowDiscountPercent
            : shadowDiscountValue
        ],
        [
            $subsidy,
            'percent' == $subsidyType.val()
                ? shadowSubsidyPercent
            : shadowSubsidyValue.convert($subsidyType.val(), {rates:courses}).getAmount()
        ],
        [
            $agentCommissionValuePercent,
            agentCommissionValuePercent
        ],
        [
            $agentCommissionValueAmount,
            agentCommissionValueAmount
        ]
    ];
    data
        .filter((v) => Math.abs(cleanNumericValue(v[0]) - v[1]) > 0.0001)
        .forEach((v) => setValueAndMaskIt(v[0], v[1]))
    // лейблы валюты в некоторых полях должны быть по валюте лизинга
    let label = $('.form-name-currency-leasing').find(':checked').siblings('.radio-label').text().trim();

    $('.form-name-leftover-price, .form-name-summ, .form-name-agent-commission')
        .find('[data-units]')
        .attr('data-units', label)
    let $paymentType = $('#calculator_form_residualPaymentOrder');

    if (residueAmount < 0.0001) {
        setReadonly($paymentType)
        $rvbp.not(':checked').attr('disabled', true);
    } else {
        removeReadonly($paymentType)
        toggleRvBp($paymentType)
    }

}

/**
 *
 * @param $input $
 */
function removeReadonly($input) {
    $input.removeAttr('readonly').parents('.form-control').removeClass('readonly')
    $input.each(function (i, v) {
        if (v.tagName.toLowerCase() == 'select') {
            enableSelect(v)
        }
    })
}

// отключаем в некоторых случаях переключалки радио
function toggleRvBp($select) {
    if(undefined === $select.val()) return
    switch ($select.val().trim()) {
        case 'НеОплачивается':
            $rvbp
                .attr('disabled', true)
                .filter('[value=RV]')
                .prop('checked', true)
                .removeAttr('disabled')
            break;
        case 'ВключаетсяВЛизинговыйПлатеж':
            $rvbp
                .attr('disabled', true)
                .filter('[value=BP]')
                .prop('checked', true)
                .removeAttr('disabled')
            break;
        default:
            $rvbp.attr('disabled', false)
            break;
    }
}

function setReadonly($input) {
    $input.attr('readonly', 'readonly').parents('.form-control').addClass('readonly')
    $input.each(function (i, v) {
        if (v.tagName.toLowerCase() == 'select') {
            disableSelect(v)
        }
    })
}

export default function ($document) {
    $document.on(EVENT_BINDING_REQUIRED, function (e) {
        const $target = $(e.target)
        let $form = $target.find('#calculation-form').addBack('#calculation-form');
        if ($form.length) {
            init($form);
        }else{
            initSubsidy($target)
        }
        $target.find('#calculator_form_removeMonths, #calculator_form_addMonths').click(function (ee) {
            ee.stopPropagation();ee.preventDefault();
            var $select = $('#calculator_form_leasingTimeMonths')
            setValue($select.get(0), $(this).attr('data-months'));
            $select.trigger('change')
        })
        let $results = $target.find('.js-calculation-result')
        if($results.length){
            $('html').animate({ scrollTop: ($results.position().top - $('.header').innerHeight())} ,500 );
        }
    }).on('counterparty-added', function (e){
        updateZones(['.form-name-dealer-relation','.form-name-lessee-relation'])
    })
}
