import {EVENT_BINDING_REQUIRED} from "./events";
import {toggleValueClass}       from "./functions";
// генерация последовательностей например для запросов или "уникальных" айдишников
var uniqId = (function () {
    var i = 0;
    return function () {
        return i++;
    }
})();
const keyCode = {
    BACKSPACE: 8,
    COMMA: 188,
    DELETE: 46,
    DOWN: 40,
    END: 35,
    ENTER: 13,
    ESCAPE: 27,
    HOME: 36,
    LEFT: 37,
    PAGE_DOWN: 34,
    PAGE_UP: 33,
    PERIOD: 190,
    RIGHT: 39,
    SPACE: 32,
    TAB: 9,
    UP: 38
}
let suppressKeyPress       = false,
    suppressInput          = false,
    suppressKeyPressRepeat = false;

function scrollTo($element) {
    if (!$element.length) return;
    const $parent = $element.parent();
    var borderTop,
        paddingTop,
        offset,
        scroll,
        elementHeight,
        itemHeight;
    if ($parent.outerHeight() < $parent.prop("scrollHeight")) {
        borderTop = parseFloat($parent.css("borderTopWidth")) || 0;
        paddingTop = parseFloat($parent.css("paddingTop")) || 0;
        offset = $element.offset().top - $parent.offset().top - borderTop - paddingTop;
        scroll = $parent.scrollTop();
        elementHeight = $parent.height();
        itemHeight = $element.outerHeight();
        if (offset < 0) {
            $parent.scrollTop(scroll + offset);
        } else if (offset + itemHeight > elementHeight) {
            $parent.scrollTop(scroll + offset - elementHeight + itemHeight);
        }
    }
}

function _keyEvent(keyEvent, event) {
    const $textInput = $(event.currentTarget);
    const $autocompleteContainer = $textInput.siblings('.js-autocomplete-container');
    if ($autocompleteContainer.find('[data-id]').length && $autocompleteContainer.is(':visible')) {
        _move(keyEvent, event);
        // Prevents moving cursor to beginning/end of the text field in some browsers
        event.preventDefault();
    }
}

function _move(direction, event) {
    const $textInput = $(event.currentTarget);
    const $autocompleteContainer = $textInput.siblings('.js-autocomplete-container');
    const $items = $autocompleteContainer.find('[data-id]');
    let index = $items.filter('.selected').index();
    if ($items.length) {
        var step = /Page$/.test(direction)
                   ? 7
                   : 1;
        var multiplier = /^next/.test(direction)
                         ? 1
                         : -1;
        if (index >= 0) {
            index = index + step * multiplier
        }
        if (index < 0) {
            index = 0;
        } else if (index > ($items.length - 1)) {
            index = $items.length - 1;
        }
        $items.eq(index).addClass('selected').siblings().removeClass('selected');
        scrollTo($items.eq(index))
    }
}
function setInputValue($textInput, text, id) {
    let $targetInput = $textInput.siblings('[type=hidden]');
    if ($targetInput.length) {
        // есть скрытый инпут - в него засовываем айдишник, а в обычный инпут - текст
        $textInput.val(text);
    } else {
        // в противном случае в видимый инпут засовываем айдишник
        $targetInput = $textInput;
    }
    $textInput.attr('data-chosen-value', text)
    $targetInput.val(id).trigger('autocomplete-change');
    $textInput.siblings('.js-autocomplete-container').html("")
}
function onKeypressKeydown(event) {
    const $textInput = $(this);
    switch (event.type) {
        case 'keydown':
            if ($textInput.prop("readOnly")) {
                suppressKeyPress = true;
                suppressInput = true;
                suppressKeyPressRepeat = true;
                return;
            }
            suppressKeyPress = false;
            suppressInput = false;
            suppressKeyPressRepeat = false;
            switch (event.keyCode) {
                case keyCode.PAGE_UP:
                    suppressKeyPress = true;
                    _keyEvent("previousPage", event);
                    break;
                case keyCode.PAGE_DOWN:
                    suppressKeyPress = true;
                    _keyEvent("nextPage", event);
                    break;
                case keyCode.UP:
                    suppressKeyPress = true;
                    _keyEvent("previous", event);
                    break;
                case keyCode.DOWN:
                    suppressKeyPress = true;
                    _keyEvent("next", event);
                    break;
                case keyCode.ENTER:
                    // // when menu is open and has focus
                    // if (this.menu.active) {
                    //
                    //     // #6055 - Opera still allows the keypress to occur
                    //     // which causes forms to submit
                    suppressKeyPress = true;
                    let $selected = $textInput.siblings('.js-autocomplete-container').find('.selected');
                    setInputValue($textInput, $selected.text().trim(), $selected.attr('data-id'))
                    event.preventDefault();
                    //     this.menu.select(event);
                    // }
                    break;
                case keyCode.TAB:
                    // if (this.menu.active) {
                    //     this.menu.select(event);
                    // }
                    break;
                case keyCode.ESCAPE:
                    $textInput.siblings('.js-autocomplete-container').hide()
                    // if (this.menu.element.is(":visible")) {
                    //     close(event);
                    // Different browsers have different default behavior for escape
                    // Single press can mean undo or clear
                    // Double press in IE means clear the whole form
                    event.preventDefault();
                    // }
                    break;
            }
            break;
        case 'keypress':
            if (suppressKeyPress) {
                suppressKeyPress = false;
                // if (this.menu.element.is(":visible")) {
                event.preventDefault();
                // }
                return;
            }
            if (suppressKeyPressRepeat) {
                return;
            }
            // Replicate some key handlers to allow them to repeat in Firefox and Opera
            switch (event.keyCode) {
                case keyCode.PAGE_UP:
                    _keyEvent("previousPage", event);
                    break;
                case keyCode.PAGE_DOWN:
                    _keyEvent("nextPage", event);
                    break;
                case keyCode.UP:
                    _keyEvent("previous", event);
                    break;
                case keyCode.DOWN:
                    _keyEvent("next", event);
                    break;
            }
    }
}

export default function ($document) {
    $document
        .on('keydown keypress', 'input.js-autocomplete[data-url]', onKeypressKeydown)
        .on('input focusin focusout', 'input.js-autocomplete[data-url][data-autocomplete-id]', function (e) {
            const $textInput = $(e.currentTarget);
            const $autocompleteContainer = $textInput.siblings('.js-autocomplete-container');
            switch (e.type) {
                case 'focusout':
                    $autocompleteContainer.hide();
                    return;
                case 'focusin':
                    $autocompleteContainer.show();
                    return;
            }
            const charLimit = $textInput.attr('data-char-limit')
                              ? parseInt($textInput.attr('data-char-limit'))
                              : 2;
            const value = $textInput.val();
            // новый ид запроса генерируется даже когда значение стирается
            // чтобы автокомплит случайно не подставился к пустому инпуту
            const requestId = uniqId();
            $textInput.data('request-id', requestId);
            if (
                null === value
                || value.length < charLimit
                || (!!$textInput.attr('data-chosen-value') && value != $textInput.attr('data-chosen-value'))
            ) {
                $textInput
                    .removeClass('loading')
                    .attr('data-chosen-value', "")
                    .siblings('[type=hidden]')
                    .val("")
                    .trigger('autocomplete-change');
                $autocompleteContainer.html("");
                return;
            }
            $document.trigger('autocomplete:query', {
                id: $textInput.attr('data-autocomplete-id'),
                url: $textInput.attr('data-url'),
                query: value,
                requestId: requestId
            })
        })
        .on('autocomplete:query', function (e, data) {
            const selector = `input.js-autocomplete[data-autocomplete-id=${data.id}]`;
            $.ajax(data.url, {
                dataType: 'html',
                data: {
                    query: data.query
                },
                beforeSend: function () {
                    $(selector).addClass('loading');
                },
                complete: function () {
                    if (data.requestId !== $(selector).data('request-id')) return;
                    $(selector).removeClass('loading');
                },
                error: function () {
                    if (data.requestId !== $(selector).data('request-id')) return;
                    if (window.customErrorHandler && typeof window.customErrorHandler === 'function') {
                        window.customErrorHandler.apply(this, arguments)
                    }
                    console.error(arguments);
                },
                success: function (html) {
                    if (data.requestId !== $(selector).data('request-id')) return;
                    $document.trigger('autocomplete:result', {
                        id: data.id,
                        html: html
                    });
                }
            })
        })
        .on('autocomplete:result', function (e, data) {
            const $textInput = $(`input.js-autocomplete[data-autocomplete-id=${data.id}]`);
            if ($textInput.length) {
                $textInput.siblings('.js-autocomplete-container').html(data.html).show();
            }
        })
        .on('mousedown', '.js-autocomplete-container li[data-id]', function (e) {
            // клик по элементу автокомплита
            // почему сделано именно mousedown и не ждем "отжима" mouseup или не click
            // потому что срабатывает раньше чем focusout, на который опирается скрытие списка автокомплита
            const $e = $(e.currentTarget);
            const $autocompleteContainer = $e.closest('.js-autocomplete-container');
            const $textInput = $autocompleteContainer.siblings('.js-autocomplete');
            setInputValue($textInput, $e.text().trim(), $e.attr('data-id'))
        })
        .on(EVENT_BINDING_REQUIRED, function (e) {
            // это событие для биндинга динамики на элементы внутри dom элемента.
            // в качестве target может быть document или кусок html загруженый с сервера.
            const $el = $(e.target);
            $el
                .find('.js-autocomplete[data-url]')
                .each(function (i, v) {
                    // автокомплит состоит из двух инпутов и блока для вывода списка автокомплита
                    // первый инпут виден пользователю и заполняется текстом руками, и в него же вставляется автокомплит
                    // второй инпут скрытый и в него вставляется значение из списка при клике на него
                    // поле автокомплита создается если не найдено в изначальной разметке
                    const $textInput = $(v);
                    // к моменту окончания запроса в странице может и не быть инпута, например если он был в попапе
                    // когда с сервера прилетает список автокомплита - в документе по этому селектору ищется инпут
                    $textInput.attr('data-autocomplete-id', uniqId());
                    // счетчик запросов нужен чтобы избавиться от состояния гонки
                    $textInput.data('request-counter', uniqId());
                    // контейнер куда будет дописываться список элементов
                    if (!$textInput.siblings('.js-autocomplete-container').length) {
                        $('<div/>', {class: 'js-autocomplete-container autocomplete-container'}).insertAfter($textInput)
                    }
                    $textInput.attr('data-chosen-value', $textInput.val())
                });
            $el
                .find('input:visible, textarea')
                .each(function (i, v) {
                    const $this = $(v);
                    toggleValueClass($this, $this.closest('.form-control'));
                })
        })
}

export function resetAutocomplete($form) {
    const $autocomplete = $form.find('.js-autocomplete');
    if ($autocomplete.length) {
        $autocomplete.val("").attr('data-chosen-value', "");
        $autocomplete.siblings('[type=hidden]').val("");
    }
}
