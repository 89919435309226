import {createApp} from "@vue/runtime-dom";
import {createErrorStore} from "../utils";
import list from './list'

export default function ($document) {
    if ($document.find('#vue-estimate-index').length) {
        const app = createApp(list, window.vueData);
        app.use(createErrorStore())
        app.mount('#vue-estimate-index')
    }
}