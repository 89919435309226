<template>
  <div
      v-bind:class="[
		  	'form-control form-control-select',
		  	 {'has-value':value.length>0,'error': errors.length>0}
		  	 ]">
		<div class="form-control-select-label" v-bind:data-label="$attrs.label||''" ref="container">
			<select
				class="searchable"
        v-bind:name="$attrs.name||''"
				ref="select"
				v-model="value"
				@update:modelValue="$emit('update:value', $event)"
			>
				<option></option>
				<option v-for="opt in options" v-bind:value="opt.value">{{ opt.label }}</option>
			</select>
			<div class="label-title floating-label-title">{{$attrs.label||''}}</div>
		</div>
    <ul class="parsley-errors-list" v-if="errors.length>0">
      <li v-for="e in errors">{{ e }}</li>
    </ul>
	</div>
</template>
<style>
</style>
<script>
  import {EVENT_BINDING_REQUIRED} from "../../events";
  import {errorSupport} from "../../mixin/error-support";

  export default {
    mixins:[errorSupport],
    emits: ['update:value'],
    props: {
      value: {type: String, required: true},
      options: {type: Array, required: true}
    },
    mounted() {
      $(this.$refs.container).trigger(EVENT_BINDING_REQUIRED)
    }
  }
</script>