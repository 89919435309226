<template>
  <div class="contact">
    <div class="entity-head">
      <div class="h4">Контактное лицо (не подписант)</div>
      <a @click="onDelete">Удалить</a>
    </div>
    <div class="contact body">
      <div class="fieldset">
        <TextControl
            :value="value.lastName"
            @update:value="onPropChange('lastName', $event)"
            class="lastName"
            label="Фамилия"
            :key=keys.lastName
        />
        <TextControl
            :value="value.initials"
            @update:value="onPropChange('initials', $event)"
            class="initials"
            label="Инициалы"
        />
        <TextControl
            :value="value.job"
            @update:value="onPropChange('job', $event)"
            class="job"
            label="Должность"
        />
      </div>
      <div class="fieldset">
        <TextControl
            :value="value.phone"
            @update:value="onPropChange('phone', $event)"
            mask="phone"
            class="phone"
            label="Контактный телефон"
        />
        <TextControl
            :value="value.email"
            @update:value="onPropChange('email', $event)"
            mask="email"
            type="text"
            class="email"
            label="Электронная почта"
        />
      </div>
    </div>
  </div>
</template>
<style scoped>
.contact .form-control {
  width: 33.333333%
}
</style>
<script>
import TextControl from "../components/form/TextControl";

export default {
  props: {
    value: {type: Object, required: true},
    onDelete:{type:Function, required:true}
  },
  emits:['update:value'],
  data(){
    return{
      keys:{
        lastName:`${this.$.vnode.key}.lastName`
      }
    }
  },
  methods: {
	  getProps(){
      return {
        guid:this.value.guid,
        lastName:this.value.lastName,
        initials:this.value.initials,
        job:this.value.job,
        phone:this.value.phone,
        email:this.value.email
      }
    },
    onPropChange(prop, value) {
      const props = this.getProps()
      props[prop]=value
      this.$emit('update:value', props)
    }
  },
  components: {TextControl},
}
</script>