import {EVENT_BINDING_REQUIRED} from "../../../../js/events";

function makeStatus(files) {
    if (!files.length) return "Прикрепите файл";
    let status = [];
    for (let i=0;i<files.length;i++){
        status.push(files[i].name)
    }
    return status.join(", ")
}

export default function ($document) {
    $document.on(EVENT_BINDING_REQUIRED, function (event) {
        $(event.target)
            .find('.form-control input[type=file]')
            .addBack('.form-control input[type=file]')
            .on('change', function (e) {
                $(this).siblings('.form-control-file-status').text(
                    makeStatus(e.target.files)
                )
            })
    })
}