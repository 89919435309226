export const EVENT_FORM_CLEAN = "form.clean";
export const EVENT_DOM_UPDATE = "update";
export const EVENT_BINDING_REQUIRED = "js.bind";
export const FANCYBOX_CONTENT_LOADED = "afterLoad.fb";
export const ATTR_DATA_UPDATE_URL = 'data-update-url';
export const ATTR_DATA_UPDATE_URL_SELECTOR = '[' + ATTR_DATA_UPDATE_URL + ']';
export const ATTR_DATA_ACTION_URL = 'data-action-url';
export const ATTR_DATA_ACTION_URL_SELECTOR = '[' + ATTR_DATA_ACTION_URL + ']';
export const FORM_EVENT_SUBMIT = "submit";
export const FORM_EVENT_SUBMIT_FILTER = "submit:filter";
export const FORM_EVENT_SUBMIT_ACTION = "submit:action";
export const FORM_INPUT_RESET = "reset:form";
