import {EVENT_BINDING_REQUIRED} from "../../../../js/events";
import {cleanNumericValue, setValueAndMaskIt} from "../../../../plugins/jquery-mask/mask";


const createStep = (positive, val) => positive ? val : -1 * val
const reducingInterval = (interval) => Math.max(50, interval - 100)
const firstInterval = 300

export default function ($document) {
    $document.on('mousedown', '.number-action', function (e) {
        const $target = $(e.currentTarget)
        let run = true
        $target.one('mouseup mouseout', () => {
            run = false
        })

        let $input = $target.siblings('input')
        if (!$input.length) return;

        $input = $input.eq(0);

        const step = createStep(
            $target.is('.number-increase'),
            parseFloat($input.attr('data-step') || 1)
        )

        const min = parseFloat($input.attr('data-min') || 0)
        const max = parseFloat($input.attr('data-max') || 0)

        const modify = ($input, step) => {
            const val = cleanNumericValue($input) + step
            if (val <= min) {
                setValueAndMaskIt($input, min)
                run = false
            } else if (val >= max) {
                setValueAndMaskIt($input, max)
                run = false
            } else {
                setValueAndMaskIt($input, val)
            }
        }

        const exec = (interval) => {
            if (!run) return
            modify($input, step)
            setTimeout(() => {
                exec(reducingInterval(interval))
            }, interval)
        }

        exec(firstInterval)

    })
}
