<template>
  <div v-bind:class="[
		'form-control form-control-textarea',
		{'readonly':$attrs.readonly||false,'has-value':value.length>0,'error': errors.length>0}
	]">
    <label class="form-control-textarea-label" v-bind:data-label="$attrs.label||''">
      <textarea
          v-model=value
          @update:modelValue="$emit('update:value', $event)"
          :rows="$attrs.rows||3"
      ></textarea>

      <span class="floating-label-title label-title">{{ $attrs.label || '' }}</span>
    </label>
    <ul class="parsley-errors-list" v-if="errors.length>0">
      <li v-for="e in errors">{{ e }}</li>
    </ul>
  </div>
</template>
<style scoped>
.form-control-textarea {
  flex-grow: 1;
}

.form-control textarea {
  height: auto;
}
</style>
<script>
import {errorSupport} from "../../mixin/error-support";

export default {
  mixins: [errorSupport],
  props: {
    value: {type: String, required: false, default: ""}
  },
  emits: ['update:value']
}
</script>