<template>
  <svg v-bind:class="['svg-icon', svg_id]">
    <use :xlink:href=href></use>
  </svg>
</template>
<script>
export default {
  props: {
    name: String
  },
  computed: {
    svg_id() {
      return `svg-icon-${this.name}`
    },
    href() {
      return `/img/svg-sprite.svg#${this.svg_id}`
    }
  }
}
</script>