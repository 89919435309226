function findLastParent($element, selector) {
    return $element.parents(selector).last()
}
/**
 * структура завернутых в дополнительные обертки инпутов формы может быть сложной
 * иногда надо стилизовать различные элементы которые нельзя достать через css
 * для этого родителю-обёртке инпута добавляются-удаляются классы состояний
 * @param $document
 * @param parentSelector
 * @param hoverClass
 * @param focusClass
 * @param activeClass
 */
export default function ($document, parentSelector, hoverClass, focusClass, activeClass) {
    $document.on('js.bind', function (e) {
        $(e.target)
            .find('input[type=checkbox], input[type=text], textarea')
            .hover(function () {
                findLastParent($(this), parentSelector).addClass(hoverClass)
            }, function () {
                findLastParent($(this), parentSelector).removeClass(hoverClass)
            })
            .focus(function () {
                findLastParent($(this), parentSelector).addClass(focusClass)
            })
            .blur(function () {
                findLastParent($(this), parentSelector).removeClass(focusClass).removeClass(activeClass)
            })
            .on('mousedown touchstart', function () {
                findLastParent($(this), parentSelector).addClass(activeClass)
            })
            .on('mouseup touchend', function () {
                findLastParent($(this), parentSelector).removeClass(activeClass)
            })
    })
}
