import axios from "axios";

const api = axios.create({
    headers: {
        'Content-Type': 'application/json',
        'X-Requested-With': 'XMLHttpRequest'
    }
})
const onResultGeneric = (json) => {
    if (!json.hasOwnProperty('actions')) return;
    const actions = json.actions
    if (!(actions instanceof Array)) return;

    actions.forEach(a => {
        switch (a) {
            case 'close':
                $.fancybox.close();
                break;
            case 'toast':
                $('<div />', {
                    html: json.params.toast
                }).showAsToast(json.params.autoClose)
                break;
            case "redirect":
                window.location = json.params.redirect;
                break;
            case "dispatch":
                $(document).trigger(json.params.event, json.params.eventParams || null)
                break;
        }
    })
}

api.interceptors.response.use(
    (response) => {
        onResultGeneric(response.data)
        return Promise.resolve(response)
    },
    (error) => {
        onResultGeneric(error.response.data)
        return Promise.reject(error)
    }
)

export default api