<template>
  <ModalForm
      :action="api.save"
      :loading="loading"
      @update:loading="e=>loading=e"
      :validate="validate"
      :get-form-data="getFormData"
      :on-response="onResult"
      ref="form"
  >
    <Card title="Основные данные" :open-at-start="true" :collapsible="false">
      <Fieldset>
        <TextControl
            style="width: 230px;"
            v-bind:value="number"
            @update:value="e=>number=e"
            label="Номер договора"
        />
        <DateControl
            style="width: 140px;"
            v-bind:value="date"
            @update:value="e=>date=e"
            label="Дата"
        />
        <TextControl
            class="fg-1"
            v-bind:value="subject"
            :readonly="true"
            label="Предмет лизинга"
        />
      </Fieldset>
      <br>
      <Fieldset>
        <TextControl
            class="fg-1"
            v-bind:value="presentation"
            @update:value="e=>presentation=e"
            label="Представление в документах предмета лизинга"
        />
      </Fieldset>
    </Card>
    <Card title="Участники сделки" :open-at-start="true" :collapsible="true">
      <Fieldset>
        <SelectControl
            v-bind:value="lessor"
            :options="options.lessor"
            @update:value="e=>lessor=e"
            class="fg-1"
            label="Лизингодатель"
        />
      </Fieldset>
      <br>
      <Fieldset>
        <SearchableSelect
            :options="options.dealer"
            class="fg-1"
            v-bind:value="dealer"
            @update:value="e=>dealer=e"
            label="Дилер"
        />
        <SearchableSelect
            :options="options.lessee"
            class="fg-1"
            v-bind:value="lessee"
            @update:value="e=>lessee=e"
            label="Лизингополучатель"
        />
      </Fieldset>
      <br>
      <Fieldset>
        <TextControl
            class="fg-1"
            v-bind:value="saleAdvance.percent"
            @update:value="e=>saleAdvance.percent=e"
            label="Размер аванса по ДКП в %"
        />
        <TextControl
            class="fg-1"
            v-bind:value="saleAdvance.value"
            @update:value="e=>saleAdvance.value=e"
            label="Размер аванса по ДКП"
        />
        <span class="form-control fg-1"/>
        <span class="form-control fg-1"/>
      </Fieldset>
      <br>
      <Fieldset>
        <TextareaControl
            class="fg-1"
            v-bind:value="advanceCondition"
            @update:value="e=>advanceCondition=e"
            label="Условия авансового платежа"
        />
      </Fieldset>
      <br>
      <Fieldset>
        <TextControl
            class="fg-1"
            v-bind:value="saleMain.percent"
            @update:value="e=>saleMain.percent=e"
            label="Основной платеж ДКП в %"
        />
        <TextControl
            class="fg-1"
            v-bind:value="saleMain.value"
            @update:value="e=>saleMain.value=e"
            label="Размер основного платежа по ДКП"
        />
        <span class="form-control fg-1"/>
        <span class="form-control fg-1"/>
      </Fieldset>
      <br>
      <Fieldset>
        <TextareaControl
            class="fg-1"
            v-bind:value="mainCondition"
            @update:value="e=>mainCondition=e"
            label="Условия основного платежа"
        />
      </Fieldset>
    </Card>
    <Card title="Прочие условия" :collapsible="true" :open-at-start="true">
      <Fieldset>
        <DateControl
            v-bind:value=deliveryDate
            @update:value="e=>deliveryDate=e"
            label="Ожидаемая дата отгрузки"
        />
      </Fieldset>
      <br>
      <Fieldset>
        <TextareaControl
            v-bind:value="deliveryPlace"
            @update:value="e=>deliveryPlace=e"
            label="Место поставки"
        />
      </Fieldset>
    </Card>
    <BottomPanel>
      <FlexRow>
        <button class="button button-primary"
                @click="click"
                v-bind:disabled="disabled">Сохранить
        </button>
      </FlexRow>
      <FlexRow>
        <FancyboxLink :href="api.bugReport" title="Сообщить об ошибке">Сообщить об ошибке</FancyboxLink>
      </FlexRow>
    </BottomPanel>
  </ModalForm>
</template>
<script>
import Card from "../components/Card";
import Fieldset from "../components/form/Fieldset";
import TextControl from "../components/form/TextControl";
import DateControl from "../components/form/DateControl";
import TextareaControl from "../components/form/TextareaControl";
import SelectControl from "../components/form/SelectControl";
import SearchableSelect from "../components/form/SearchableSelect";
import BottomPanel from "../components/form/BottomPanel";
import FlexRow from "../components/layout/FlexRow";
import FancyboxLink from "../components/FancyboxLink";
import {validateObject} from "../utils";
import SimpleForm from "../components/form/SimpleForm";
import ModalForm from "../components/form/ModalForm";

export default {
  components: {
    ModalForm,
    SimpleForm,
    FancyboxLink,
    FlexRow,
    BottomPanel, SearchableSelect, SelectControl, TextareaControl, DateControl, TextControl, Fieldset, Card
  },
  props: {
    api: {
      type: Object,
      required: true,
      validator: function (value) {
        const missing = validateObject(value, [
          'bugReport',
          'save'
        ])
        if (missing.length) {
          console.error(`missing keys: [${missing.join(',')}]`)
        }
        return missing.length === 0
      }
    },
    options: {
      type: Object, required: true, validator: function (value) {
        const missing = validateObject(value, [
          'dealer',
          'lessee',
          'lessor'
        ])
        if (missing.length) {
          console.error(`missing keys: [${missing.join(',')}]`)
        }
        return missing.length === 0
      }
    },
    saleAmount: {type: Number, required: true},
    initial: {
      type: Object,
      required: true,
      validator: function (value) {
        const missing = validateObject(value, [
          'number',
          'date',
          'subject',
          'presentation',
          'dealer',
          'lessor',
          'lessee',
          'saleAdvance',
          'saleMain',
          'advanceCondition',
          'mainCondition',
          'deliveryDate',
          'deliveryPlace',
        ])
        if (missing.length) {
          console.error(`missing keys: [${missing.join(',')}]`)
        }
        return missing.length === 0
      }
    }
  },
  computed: {
    disabled() {
      return this.loading
    }
  },
  data() {
    return {
      loading: false,
      ...this.initial
    }
  },
  methods: {
    click() {
      this.$refs.form.submit()
    },
    validate() {
      return Promise.resolve()
    },
    onResult() {

    },
    getFormData() {
      return JSON.parse(JSON.stringify({
        "number":this.number,
        "date":this.date,
        "presentation":this.presentation,
        "dealer":!!this.dealer ? parseInt(this.dealer):null,
        "lessor":!!this.lessor ? parseInt(this.lessor) : null,
        "lessee":!!this.lessee ? parseInt(this.lessee) : null,
        "saleAdvance":{
          "value":parseFloat(this.saleAdvance.value),"percent":parseFloat(this.saleAdvance.percent)
        },
        "advanceCondition":this.advanceCondition,
        "saleMain":{
          "value":parseFloat(this.saleMain.value),"percent":parseFloat(this.saleMain.percent)
        },
        "mainCondition":this.mainCondition,
        "deliveryDate":this.deliveryDate,
        "deliveryPlace":this.deliveryPlace
      }))
    }
  }
}
</script>
<style scoped>
.fg-1 {
  flex-grow: 1;
  flex-basis: 0;
}

.w25 {
  width: 25%;
}
</style>