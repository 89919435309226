<template>
  <ul class="breadcrumbs">
    <li v-for="link in links" class="breadcrumbs-item">
      <a v-bind:href=link.url>{{link.title}}</a>
    </li>
    <li class="breadcrumbs-item">{{last}}</li>
  </ul>
</template>
<style scoped>
.breadcrumbs-item:not(:last-child):after{
  content:"/";
  display: inline-block;
  margin-left: 5px;
  margin-right: 5px;
}
</style>
<script>
export default {
  props: {
    last: String,
    links: Array
  }
}
</script>