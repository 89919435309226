<template>
	<transition-group name="list" tag="div" @leave="transitionLeave" appear @enter="transitionEnter">
		<slot/>
	</transition-group>
</template>
<script>
  import gsap from "gsap/all";
  import {ANIMATION_DURATION} from "../../const";

  export default {
    methods: {
      transitionLeave(el, done) {
        gsap.to(el, {
          opacity: 0,
          height: 0,
	        paddingTop:0,
          x: '100%',
          duration: ANIMATION_DURATION,
          onComplete: done
        })
      },
      transitionEnter(el, done) {
        gsap.fromTo(el,
          {
            opacity: 0
          },
          {
            opacity: 1,
            duration: ANIMATION_DURATION,
            onComplete: done
          })
      }
    }
  }
</script>