import {EVENT_BINDING_REQUIRED} from "../../../js/events";
import Scrollbar                from "smooth-scrollbar";

export default function ($document) {

    $document.on(EVENT_BINDING_REQUIRED, function (e) {
        // $(e.target)
        //     .find('.js-custom-scrollbar')
        //     .addBack('.js-custom-scrollbar')
        //     .each(function (i, v) {
        //         // для случая когда скролл уже есть его надо убрать
        //         Scrollbar.destroy(v)
        //         setTimeout(function (){
        //             Scrollbar.init(v, {})
        //         },0)
        //         console.dir(v)
        //     })
        // // каждый раз скроллбар втыкает в страницу инлайн стили. их можно убрать так.
        // Scrollbar.detachStyle()
    })
}
