import { render } from "./Card.vue?vue&type=template&id=410353dc"
import script from "./Card.vue?vue&type=script&lang=js"
export * from "./Card.vue?vue&type=script&lang=js"
script.render = render
/* hot reload */
if (module.hot) {
  script.__hmrId = "410353dc"
  const api = __VUE_HMR_RUNTIME__
  module.hot.accept()
  if (!api.createRecord('410353dc', script)) {
    api.reload('410353dc', script)
  }
  
  module.hot.accept("./Card.vue?vue&type=template&id=410353dc", () => {
    api.rerender('410353dc', render)
  })

}

script.__file = "src/js/components/Card.vue"

export default script