import {MASK_DATE, MASK_EMAIL, MASK_INN, MASK_PHONE} from "../../plugins/jquery-mask/mask";
import Inputmask from "inputmask";

export function addMaskDirective(vueApp) {
    vueApp.directive('inputMask', {
        beforeMount(el, binding, vnode, prevVnode) {
            let im;
            switch (binding.value) {
                case MASK_INN:
                    im = new Inputmask({
                        mask: '9999999999',
                        showMaskOnHover: false,
                        autoUnmask:true
                        // , placeholder: '' раскомментировать на случай если надоест подчеркивание
                    })
                    break;
                case MASK_EMAIL:
                    im = new Inputmask('email', {
                        showMaskOnHover: false,
                        autoUnmask:true
                    })
                    break;
                case MASK_PHONE:
                    im = new Inputmask({
                        mask: '+7 (999) 999 99 99',
                        showMaskOnHover: false,
                        autoUnmask:true
                    })
                    break;
                default:
                    return
            }
            im._plugin_mask = im
            im.mask(el)
        }
    })
}