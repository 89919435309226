import { render } from "./Pagination.vue?vue&type=template&id=71f24466"
import script from "./Pagination.vue?vue&type=script&lang=js"
export * from "./Pagination.vue?vue&type=script&lang=js"
script.render = render
/* hot reload */
if (module.hot) {
  script.__hmrId = "71f24466"
  const api = __VUE_HMR_RUNTIME__
  module.hot.accept()
  if (!api.createRecord('71f24466', script)) {
    api.reload('71f24466', script)
  }
  
  module.hot.accept("./Pagination.vue?vue&type=template&id=71f24466", () => {
    api.rerender('71f24466', render)
  })

}

script.__file = "src/js/components/Pagination.vue"

export default script