import keycodes from 'jquery-ui/ui/keycode'
import {EVENT_BINDING_REQUIRED} from "../../../js/events";

function select($dropdown, down) {
    const $focusSet = $dropdown.find('[tabindex]');
    if (!$focusSet.length) return;
    const focusIndex = $focusSet.index($focusSet.filter(':focus'));
    const nextIndex = down
        ? (focusIndex + 1) % $focusSet.length
        : ($focusSet.length + focusIndex - 1) % $focusSet.length;
    $focusSet.get(nextIndex).focus()
}

function createDropdown($document, $wrapper) {
    if (!$wrapper.length) return;
    $wrapper
        .each(function (i, v) {
            const $container = $(v);
            const $button = $container.children('.button')
            let clickListener;
            clickListener = function (e) {
                if (!$.contains(v, e.target)) {
                    $button.removeClass('open')
                    $document.off('click', clickListener)
                }
            }

            function openDropdown() {
                $document.on('click', clickListener)
                $button.addClass('open')
            }

            function closeDropdown() {
                $document.off('click', clickListener)
                $button.removeClass('open')
            }

            $container
                .on('click', '[tabindex=-1]', function () {
                    closeDropdown()
                    $button.focus()
                })
                .on('keydown', function (e) {
                    switch (e.which) {
                        case $.ui.keyCode.DOWN:
                        case $.ui.keyCode.UP:
                            if ($button.hasClass('open')) {
                                e.preventDefault()
                                select($container.find('.button-action-dropdown'), e.which === $.ui.keyCode.DOWN)
                            }
                            break;
                        case $.ui.keyCode.ESCAPE:
                            closeDropdown()
                            $button.focus()
                            break;
                        case $.ui.keyCode.TAB:
                            setTimeout(function () {
                                closeDropdown($button)
                            }, 1)
                            break;
                        case $.ui.keyCode.SPACE:
                            if ($button.hasClass('open') && !$(e.target).is(".button")) {
                                // не прокручивать страницу на пробел если открыто меню
                                e.preventDefault();
                            }
                            break;
                    }
                })
            $button
                .on('click', function (e) {
                    e.preventDefault();
                    if ($button.hasClass('open')) {
                        closeDropdown()
                    } else {
                        openDropdown()
                    }
                })
        })
}

const copyToClipboard = str => {
    const el = document.createElement('textarea');
    el.value = str;
    el.setAttribute('readonly', '');
    el.style.position = 'absolute';
    el.style.left = '-9999px';
    document.body.appendChild(el);
    el.select();
    document.execCommand('copy');
    document.body.removeChild(el);
};

export default function ($document) {
    $document
        .on(EVENT_BINDING_REQUIRED, function (event) {
            const $el = $(event.target);
            createDropdown($document, $el.find('.button-action-wrapper').addBack('.button-action-wrapper'))
            $el.find('[type=submit]').on('click',function (){
                $('[type=submit]').not($(this)).removeAttr('data-clicked');
                $(this).attr('data-clicked',true)
            })
        })
        .on('click', '[data-copy]', function (e) {
            e.preventDefault();
            try {
                copyToClipboard($(this).attr('data-copy'))
                $('<div/>', {text: "Скопировано: " + $(this).attr('data-copy')}).showAsToast(true)
            } catch (err) {
                console.error(err)
            }
        })
}
