<template>
  <div
      v-bind:class="[
		    'form-control form-control-date',
		    {'has-value': datepickerOpen, 'readonly':$attrs.readonly||false},
		    {'has-value':value!=null}
]"
  >
    <label class="form-control-text-label" v-bind:data-label="$attrs.label || ''">
      <input
          type="text"
          v-model="value"
          ref="date_control"
          v-bind:readonly="$attrs.readonly||false"
      >
      <span class="label-title floating-label-title">
        {{ $attrs.label || '' }}
      </span>
      <SvgIcon name="calendar"/>
    </label>
  </div>
</template>
<style scoped>
.svg-icon-calendar {
  position: absolute;
  right: 0;
  top: 0;
  bottom: 0;
  margin: auto 10px;
}

.form-control-date [data-label]:before {
  /* 1*10px svg margin, 14px svg width*/
  padding-right: 24px;
}
.form-control.form-control-date.readonly {
  pointer-events: none;
}
</style>
<script>
import $ from "jquery";
import SvgIcon from "../SvgIcon";

export default {
  components: {SvgIcon},
  props: {
    value: {type: String, required: false, nullable: true},
    pickerOptions: {
      type: Object,
      required: false,
      default: {}
    }
  },
  data() {
    return {
      datepickerOpen: false
    }
  },
  emits: ['update:value'],
  mounted() {
    const input = this.$refs.date_control
    let options = this.pickerOptions
    options.prevHtml = '<svg class="svg-icon svg-icon-datepicker-prev"><use xlink:href="/img/svg-sprite.svg#svg-icon-datepicker-prev"></use></svg>';
    options.nextHtml = '<svg class="svg-icon svg-icon-datepicker-next"><use xlink:href="/img/svg-sprite.svg#svg-icon-datepicker-next"></use></svg>';
    options.offset = 0;
    options.autoClose = true;
    options.onSelect = (formattedDate, date, datepicker) => {
      this.$emit('update:value', formattedDate)
    }
    options.position = 'bottom right'
    options.onShow = () => this.datepickerOpen = true
    options.onHide = () => this.datepickerOpen = false
    $(input).datepicker(options);
  }
}
</script>